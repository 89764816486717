/* eslint-disable no-console */

import { ageValidator } from './ageFieldsValidator';

/**
 * Validate Insurace Form
 * @param {object} values
 * @param {object} productSettings
 * @return {object} Return an object with the list of errors on Insurance form
 */
export const InsuredValidations = (values, productSettings) => {
  let errors = {};

  if (values.firstName.length > 32) {
    errors.firstName = 'Maximum 32 characters';
  }

  if (values.lastName.length > 32) {
    errors.lastName = 'Maximum 32 characters';
  }

  // TODO: I think this validation is not necessary because the First Name is optional,
  // incase use do not set the value we should send "Valued" as word document specify

  errors = ageValidator(values, productSettings, errors);

  if (!values.issueState) {
    errors.issueState = true;
  }

  if (!values.sex) {
    errors.sex = true;
  }

  if (values.issueState === 'CA') {
    if (!values.county) {
      errors.county = true;
    }
  }

  return errors;
};
