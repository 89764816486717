/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.HomePage';

export default defineMessages({
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  wizardTitle: {
    id: `${scope}.wizardTitle`,
    defaultMessage: 'New Illustration',
  },
  wizardTitleEdit: {
    id: `${scope}.wizardTitle`,
    defaultMessage: 'Edit Illustration',
  },
  continueTo: {
    id: `${scope}.continueTo`,
    defaultMessage: 'Continue to {step}',
  },
  runAndExportIllustration: {
    id: `${scope}.runAndExportIllustration`,
    defaultMessage: 'Run and Export Illustration',
  },
  insuredInformation: {
    id: `${scope}.insuredInformation`,
    defaultMessage: 'Insured Information',
  },
  faceAmountAndPremium: {
    id: `${scope}.faceAmountAndPremium`,
    defaultMessage: 'Face Amount and Premium',
  },
  outputCustomization: {
    id: `${scope}.outputCustomization`,
    defaultMessage: 'Output Customization',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First name (optional)',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last name (optional)',
  },
  sex: {
    id: `${scope}.sex`,
    defaultMessage: 'Sex',
  },
  ageValidation: {
    id: `${scope}.ageValidation`,
    defaultMessage: 'Age Validation',
  },
  birthday: {
    id: `${scope}.birthday`,
    defaultMessage: 'Date of Birth (MM/DD/YYYY)',
  },
  issueAge: {
    id: `${scope}.issueAge`,
    defaultMessage: 'Age as of last birthday',
  },
  issueState: {
    id: `${scope}.issueState`,
    defaultMessage: 'Issue State',
  },
  county: {
    id: `${scope}.county`,
    defaultMessage: 'County',
  },
  married: {
    id: `${scope}.married`,
    defaultMessage: 'Married / Civil Union / Domestic Partnership',
  },
  tobacco: {
    id: `${scope}.tobacco`,
    defaultMessage: 'Tobacco user',
  },
  revised: {
    id: `${scope}.revised`,
    defaultMessage: 'Revised illustration',
  },
  revisedHint: {
    id: `${scope}.revisedHint`,
    defaultMessage:
      'If the original illustration is different than the final offer, a new illustration will be required.',
  },
  premiumOrBenefitType: {
    id: `${scope}.premiumOrBenefitType`,
    defaultMessage: 'Premium or Benefit Type',
  },
  premiumAmount: {
    id: `${scope}.premiumAmount`,
    defaultMessage: 'Premium or Benefit Amount',
  },
  scheduledPremium: {
    id: `${scope}.scheduledPremium`,
    defaultMessage: 'Scheduled Premium',
  },
  refundOfPremium: {
    id: `${scope}.refundOfPremium`,
    defaultMessage: 'Refund of Premium',
  },
  premiumMode: {
    id: `${scope}.premiumMode`,
    defaultMessage: 'Premium Mode',
  },
  benefitDuration: {
    id: `${scope}.benefitDuration`,
    defaultMessage: 'Benefit Duration',
  },
  inflationBenefitOption: {
    id: `${scope}.inflationBenefitOption`,
    defaultMessage: 'Inflation Benefit Option',
  },
  returnOfPremium: {
    id: `${scope}.returnOfPremium`,
    defaultMessage: 'Return Of Premium',
  },
  exchange: {
    id: `${scope}.exchange`,
    defaultMessage: '1035 Exchange',
  },
  occReport: {
    id: `${scope}.occReport`,
    defaultMessage: 'OCC Report',
  },
  irrColumn: {
    id: `${scope}.irrColumn`,
    defaultMessage: 'IRR Column Hint',
  },
  requiredOutput: {
    id: `${scope}.requiredOutput`,
    defaultMessage: 'Required Documents',
  },
  selectAdditionalOutput: {
    id: `${scope}.selectAdditionalOutput`,
    defaultMessage:
      'Select additional documents to be included in the illustration output',
  },
  generalLedger: {
    id: `${scope}.generalLedger`,
    defaultMessage: 'General Ledger',
  },
  premiumSummary: {
    id: `${scope}.premiumSummary`,
    defaultMessage: 'Premium Summary',
  },
  annualCost: {
    id: `${scope}.annualCost`,
    defaultMessage: 'Annual Cost Summary',
  },
  includeIrrColumn: {
    id: `${scope}.includeIrrColumn`,
    defaultMessage: 'Include IRR Column',
  },
  quickPreview: {
    id: `${scope}.quickPreview`,
    defaultMessage: 'Quick View',
  },
  occReportLabel: {
    id: `${scope}.occReportLabel`,
    defaultMessage: 'OCC Report',
  },
  monthlyCost: {
    id: `${scope}.monthlyCost`,
    defaultMessage: 'Monthly Cost Summary',
  },
  advancedPremiumFund: {
    id: `${scope}.advancedPremiumFund`,
    defaultMessage: 'Advanced Premium Fund',
  },
  lumpSumPremium: {
    id: `${scope}.lumpSumPremium`,
    defaultMessage: 'Lump Sum Premium',
  },
  existingContract: {
    id: `${scope}.existingContract`,
    defaultMessage: 'Existing contract is MEC',
  },
  preparedBy: {
    id: `${scope}.preparedBy`,
    defaultMessage: 'Presented By',
  },
  exchangeAmount: {
    id: `${scope}.exchangeAmount`,
    defaultMessage: 'Exchange Amount',
  },
  illustrationType: {
    id: `${scope}.illustrationType`,
    defaultMessage: 'Illustration Type',
  },
  yearsForMonthlyOutput: {
    id: `${scope}.yearsForMonthlyOutput`,
    defaultMessage: 'Years for Monthly Output (YY-YY)',
  },
  benefitDurationHint: {
    id: `${scope}.benefitDurationHint`,
    defaultMessage: 'Benefit duration, in years. Default is 6 years.',
  },
  benefitDurationWithoutDefaultHint: {
    id: `${scope}.benefitDurationWithoutDefaultHint`,
    defaultMessage: 'Benefit duration, in years.',
  },
  scheduledPremiumHint: {
    id: `${scope}.benefitDurationHint`,
    defaultMessage: `<div>Single Premium</div> 
        <ul style="margin:2px 2px 0px -12px"><li> The annual single premium amount in year one, day one.</li> </ul>
      <div style="margin-top:5px">5 - Pay </div>
        <ul style="margin:2px 2px 0px -12px"><li>The level premium amount or monthly level premium amount that occurs for 5 policy years.</li> </ul>
      <div style="margin-top:5px">10 - Pay </div>
         <ul style="margin:2px 2px 0px -12px"><li> The level premium amount or monthly level premium amount that occurs for 10 policy years.</li> </ul>
      <div style="margin-top:5px">Pay to Age 65</div>
         <ul style="margin:2px 2px 0px -12px"><li>The level premium amount or monthly level premium amount that occurs through age 65.</li> </ul>
      <div style="margin-top:5px">Pay to Age 100 <div>
         <ul style="margin:2px 2px 0px -12px"><li>The level premium amount or monthly level premium amount that occurs through age 100.</li> </ul>
    </div>`,
  },
  scheduledPremiumHintCMI: {
    id: `${scope}.benefitDurationHint`,
    defaultMessage: `<div>Single Premium</div> 
        <ul style="margin:2px 2px 0px -12px"><li> The annual single premium amount in year one, day one.</li> </ul>
      <div style="margin-top:5px">5 - Pay </div>
        <ul style="margin:2px 2px 0px -12px"><li>The level premium amount or monthly level premium amount that occurs for 5 policy years.</li> </ul>
      <div style="margin-top:5px">10 - Pay </div>
         <ul style="margin:2px 2px 0px -12px"><li> The level premium amount or monthly level premium amount that occurs for 10 policy years.</li> </ul>
    </div>`,
  },
  refundOfPremiumHint: {
    id: `${scope}.refundOfPremium`,
    defaultMessage: `<div>Maximum LTC Benefit</div> 
        <ul style="margin:2px 2px 0px -12px">
          <li>The refund of premium value is equal to cash surrender value.  This option provides the lowest refund of premium value, and the most LTC benefit for a given premium.</li></ul>
      <div style="margin-top:5px">One Time Step-Up</div>
        <ul style="margin:2px 2px 0px -12px">
              <li>Years 1 - 10 = 80% refund of premium.</li> 
              <li>Years 11 += 100% refund of premium.</li>
        </ul>
      <div style="margin-top:5px">Vested</div>
         <ul style="margin:2px 2px 0px -12px">
          <li>The refund of premium value is equivalent to all premium paid, minus any withdrawals, multiplied by the applicable percentage, which will grade from 85% to 100% over a number of years.</li></ul>
    </div>`,
  },
  premiumModeHint: {
    id: `${scope}.premiumModeHint`,
    defaultMessage: `<div>Annual</div> 
        <ul style="margin:2px 2px 0px -12px"><li>Level premium.</li></ul>
      <div style="margin-top:5px">Monthly</div>
        <ul style="margin:2px 2px 0px -12px">
              <li>Level premium.</li> 
              <li>Not available on Single Premium.</li>
        </ul>
    </div>`,
  },
  MessageLabel1035: {
    id: `${scope}.messageLabel1035`,
    defaultMessage:
      'Any 1035 Exchange amount will be included in your scheduled premium.',
  },
  popupMessageWarning: {
    id: `${scope}.popupMessageWarning`,
    defaultMessage:
      '*Please turn off your Pop-Up blocker to allow the Illustration Output to open.',
  },
});
