import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import CheckBoxComponent from '../CheckBoxComponent';

export const Wrapper = styled.div`
  border: 1px solid transparent;
  margin-left: 22px;
  margin-bottom: 15px;
  display: inline-flex;
  cursor: pointer;
  &.selected {
    background: linear-gradient(
        180deg,
        rgba(52, 96, 148, 0.1) 0%,
        rgba(52, 96, 148, 0.25) 100%
      ),
      url(${props => props.route}) no-repeat center;
    border: 1px solid #1c57a5;

    img {
      opacity: 0;
    }
  }

  &.disabled-selected,
  &.disabled-required {
    cursor: default;
  }
`;

const styles = () => ({
  root: {
    flexGrow: 1,
    minHeight: 70,
  },
  previewImg: {
    opacity: 0,
  },
});

const OutputPreview = ({ classes, ...props }) => (
  <React.Fragment>
    <Field
      name={props.name}
      value={props.value}
      label={props.label}
      showHelp={props.showHelp}
      textHint={props.textHint}
      checked={props.checked}
      disabled={props.disabled}
      component={CheckBoxComponent}
      onClick={props.onClick}
    />
    <Wrapper
      onClick={props.onClick}
      route={props.route}
      className={[
        props.isSelected ? 'selected' : '',
        props.disabled ? 'disabled-required' : '',
        props.disabled && props.isSelected ? 'disabled-selected' : '',
      ].join(' ')}
    >
      <img src={props.route} alt="" />
    </Wrapper>
  </React.Fragment>
);

OutputPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  route: PropTypes.string,
  textHint: PropTypes.object,
  showHelp: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default withStyles(styles)(OutputPreview);
