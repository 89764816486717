import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import InputMask from 'react-input-mask';
import {
  ErrorMessageMask,
  TextFieldStyle,
} from '../../containers/HomePage/styles';

const NumberFormatCustom = ({ inputRef, ...rest }) => (
  <InputMask mask="99-99" maskChar={null} {...rest} />
);

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const MonthYearfield = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) => (
  <React.Fragment>
    <TextFieldStyle
      type="text"
      margin="none"
      variant="filled"
      fullWidth
      {...field}
      {...rest}
      error={!!(touched[field.name] && errors[field.name])}
      InputProps={{
        disableUnderline: true,
        inputComponent: NumberFormatCustom,
      }}
      autoComplete="off"
    />
    <ErrorMessageMask component="div" name={field.name} />
  </React.Fragment>
);
MonthYearfield.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

const MonthYearField = props => <Field component={MonthYearfield} {...props} />;

export default MonthYearField;
