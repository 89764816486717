import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Fade from '@material-ui/core/Fade';
import DatePicker from 'react-datepicker';
import CloseIcon from '@material-ui/icons/Close';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InputMask from 'react-input-mask';
import Hint from '../../components/Hint';
import messages from './messages';
import {
  StyledHeader,
  Wrapper,
  StyledTable,
  StyledLink,
  SyledtHint,
  StyledCircularProgress,
  StyledPopper,
  StyledPaper,
  StyledIconButton,
  StyledTextField,
  DatesWrapper,
} from './styles';
import TablePagination from './TablePagination';
import CustomToolbar from './CustomToolbar';
import 'react-datepicker/dist/react-datepicker.css';

const NumberFormatCustom = ({ ...rest }) => (
  <InputMask mask="99/99/9999" pattern="\d*" {...rest} />
);

const IllustrationHistoryComponent = ({
  data,
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  searchText,
  isLoading,
  anchorFilter,
  idFilter,
  selectedStartDate,
  selectedEndDate,
  serverDateTime,
  minDate,
  tableMethods,
  clearDate,
  handleChangeStart,
  handleChangeEnd,
  handleOnDownload,
  handleOnFilter,
  openFilter,
  tablePaginationReactRef,
  muiDataTableRef,
  clearSelectedStartDate,
  clearSelectedEndDate,
  searchIconClass,
  closeDateFilter,
}) => {
  const startDateKeyDownHandler = datePickerInstance => {
    if (
      (datePickerInstance.target.value === '__/__/____' ||
        datePickerInstance.target.value === '0_/__/____') &&
      selectedStartDate !== null
    ) {
      clearSelectedStartDate();
    }
  };

  const endDateKeyDownHandler = datePickerInstance => {
    if (
      (datePickerInstance.target.value === '__/__/____' ||
        datePickerInstance.target.value === '0_/__/____') &&
      selectedEndDate !== null
    ) {
      clearSelectedEndDate();
    }
  };

  const tableColumns = [
    {
      name: 'fullName',
      label: 'Client Name, Age',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'insuredIssueState',
      label: 'State',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Date',
      options: {
        filter: false,
        sort: true,
        sortDirection: 'desc',
      },
    },
    {
      name: 'details',
      label: 'Details',
      options: {
        filter: false,
        sort: false,
        customHeadRender: columnMeta => (
          <TableCell
            className="details-th"
            key={`view-history-header-details-${columnMeta.index}`}
          >
            Details
            <SyledtHint>
              {' '}
              <Hint
                role="tooltip"
                text={<FormattedMessage {...messages.detailsTooltip} />}
                position="top"
              />
            </SyledtHint>
          </TableCell>
        ),
      },
    },
    {
      name: 'preparedBy',
      label: 'Presented By',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'illustrationId',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          if (value) {
            return (
              <StyledLink href={`/Illustration/${value}`} target="_blank">
                <FormattedMessage {...messages.edit} />
              </StyledLink>
            );
          }

          return '';
        },
      },
    },
  ];

  const tableOptions = {
    filter: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    search: true,
    searchText,
    download: false,
    serverSide: true,
    count,
    page,
    pagination: true,
    rowsPerPage,
    rowsPerPageOptions,
    anchorFilter,
    textLabels: {
      body: {
        noMatch: 'Sorry, no records found.',
      },
      pagination: {
        next: 'Next Page',
        previous: 'Previous Page',
        rowsPerPage: 'Rows per page:',
        displayRows: 'of',
      },
    },
    onColumnSortChange: (changedColumn, direction) => {
      tableMethods.onColumnSortChange(changedColumn, direction);
    },
    onChangePage: tableMethods.onChangePage,
    onChangeRowsPerPage: tableMethods.onChangeRowsPerPage,
    customToolbar: () => (
      <CustomToolbar
        handleOnDownload={handleOnDownload}
        handleOnFilter={handleOnFilter}
        ariaDescribedby={idFilter}
        variant="contained"
      />
    ),
    customFooter: (
      countFooter,
      pageNumberFooter,
      rowsPerPageFooter,
      changeRowsPerPage,
      changePage,
    ) => (
      <TablePagination
        count={countFooter}
        page={pageNumberFooter}
        rowsPerPage={Number(rowsPerPageFooter)}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
        options={tableOptions}
        ref={tablePaginationReactRef}
      />
    ),
    onTableChange: tableMethods.onTableChange,
    onSearchClose: tableMethods.onSearchClose,
  };
  const customTableClass = [
    data.length === 0 ? 'not-found' : '',
    openFilter || (selectedEndDate || selectedStartDate) ? 'active-filter' : '',
    searchIconClass,
  ];
  return (
    <Wrapper className={isLoading ? 'loading' : ''}>
      {isLoading && <StyledCircularProgress size={30} />}
      <StyledHeader>
        <FormattedMessage {...messages.illustrationHistory} />
      </StyledHeader>
      <StyledPopper
        id={idFilter}
        open={openFilter}
        anchorEl={anchorFilter}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={closeDateFilter}>
            <Fade {...TransitionProps} timeout={200}>
              <StyledPaper>
                <Grid container spacing={16}>
                  <Grid item xs={5}>
                    <DatesWrapper>
                      <DatePicker
                        disabledKeyboardNavigation
                        allowSameDay
                        customInput={
                          <StyledTextField
                            id="filled-name"
                            label="Start Date"
                            variant="filled"
                            InputProps={{
                              onFocus: e => e.preventDefault(),
                              disableUnderline: true,
                              inputComponent: NumberFormatCustom,
                              className:
                                selectedEndDate !== null &&
                                selectedStartDate > selectedEndDate
                                  ? 'error-date'
                                  : '',
                            }}
                          />
                        }
                        selected={selectedStartDate}
                        minDate={minDate}
                        maxDate={selectedEndDate || serverDateTime}
                        onChange={handleChangeStart}
                        onKeyDown={startDateKeyDownHandler}
                        dateFormat="MM/dd/yyyy"
                      />
                    </DatesWrapper>
                  </Grid>
                  <Grid item xs={5}>
                    <DatesWrapper>
                      <DatePicker
                        adjustDateOnChange={false}
                        disabledKeyboardNavigation
                        allowSameDay
                        selected={selectedEndDate}
                        customInput={
                          <StyledTextField
                            id="filled-name"
                            label="End Date"
                            variant="filled"
                            InputProps={{
                              onFocus: e => e.preventDefault(),
                              disableUnderline: true,
                              inputComponent: NumberFormatCustom,
                              className:
                                selectedEndDate !== null &&
                                selectedEndDate < selectedStartDate
                                  ? 'error-date'
                                  : '',
                            }}
                          />
                        }
                        selectsEnd
                        onChange={handleChangeEnd}
                        onKeyDown={endDateKeyDownHandler}
                        minDate={selectedStartDate || minDate}
                        maxDate={serverDateTime}
                        dateFormat="MM/dd/yyyy"
                      />
                    </DatesWrapper>
                  </Grid>
                  <Grid item xs={2}>
                    <StyledIconButton
                      aria-label="Clear"
                      onClick={() => {
                        clearDate();
                      }}
                    >
                      <CloseIcon />
                    </StyledIconButton>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Fade>
          </ClickAwayListener>
        )}
      </StyledPopper>
      <StyledTable
        data={data}
        columns={tableColumns}
        options={tableOptions}
        className={customTableClass}
        SelectProps={{
          NativeSelect: true,
        }}
        ref={muiDataTableRef}
      />
    </Wrapper>
  );
};

IllustrationHistoryComponent.propTypes = {
  data: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  searchText: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  anchorFilter: PropTypes.any,
  selectedStartDate: PropTypes.any,
  selectedEndDate: PropTypes.any,
  serverDateTime: PropTypes.any,
  idFilter: PropTypes.string,
  searchIconClass: PropTypes.string,
  tableMethods: PropTypes.object.isRequired,
  clearDate: PropTypes.func.isRequired,
  handleChangeStart: PropTypes.func.isRequired,
  handleChangeEnd: PropTypes.func.isRequired,
  handleOnDownload: PropTypes.func.isRequired,
  handleOnFilter: PropTypes.func.isRequired,
  closeDateFilter: PropTypes.func.isRequired,
  openFilter: PropTypes.bool.isRequired,
  tablePaginationReactRef: PropTypes.any.isRequired,
  muiDataTableRef: PropTypes.any.isRequired,
  minDate: PropTypes.any.isRequired,
  clearSelectedStartDate: PropTypes.func.isRequired,
  clearSelectedEndDate: PropTypes.func.isRequired,
};

export default IllustrationHistoryComponent;
