import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Hint from '../Hint';
import {
  EmptyCheckBoxSvg,
  FilledCheckBoxSvg,
  PartialCheckBoxSvg,
} from './checkBox-svg';

const SyledtHint = styled.div`
    position: absolute;
    left: 175px;
    top: 5px;
`;

const CheckBoxComponent = ({
  field,
  form,
  showHelp,
  textHint,
  hintPosition,
  mobileTooltip,
  partialSelect,
  isList,
  ...rest
}) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          icon={partialSelect ? <PartialCheckBoxSvg /> : <EmptyCheckBoxSvg />}
          checkedIcon={<FilledCheckBoxSvg />}
          {...field}
          {...rest}
          value=""
          checked={field.value}
        />
      }
      label={!isList ? rest.label : null}
    />
    {isList && <span>{rest.label}</span>}
    {showHelp && (
        <SyledtHint>
          <Hint
            role="tooltip"
            text={textHint}
            position={hintPosition}
            mobileTooltip={mobileTooltip}/>
      </SyledtHint>
    )}
  </div>
);

CheckBoxComponent.propTypes = {
  form: PropTypes.object,
  field: PropTypes.object,
  showHelp: PropTypes.bool,
  partialSelect: PropTypes.bool,
  textHint: PropTypes.object,
  hintPosition: PropTypes.string,
  isList: PropTypes.bool,
};

export default CheckBoxComponent;
