import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles } from '@material-ui/core/styles';
import HintSVG from './hint-svg';
import variables from '../../theme/themeVars';

function arrowGenerator() {
  return {
    opacity: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      '&::before': {
        top: '-4px',
        position: 'relative',
        borderWidth: '0 1.7em 1.7em 1.7em',
        borderColor: 'transparent transparent #fff transparent',
        zIndex: '999'
      },
      '&::after': {
        top: '-15px',
        position: 'relative',
        borderWidth: '0 1.7em 1.7em 1.7em',
        borderColor: 'transparent transparent #a8a8a8 transparent',
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      '&::before': {
        top: '11px',
        position: 'relative',
        borderWidth: '1.7em 1.7em 0 1.7em',
        borderColor: `#fff transparent transparent transparent`,
        zIndex: '999'
      },
      '&::after': {
        top: '2px',
        position: 'relative',
        borderWidth: '1.7em 1.7em 0 1.7em',
        borderColor: '#a8a8a8 transparent transparent transparent'
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      '&::before': {
        position: 'relative',
        right: '8px',
        borderWidth: '1.7em 1.7em 1.7em 0',
        borderColor: 'transparent #fff transparent transparent',
        zIndex:'999'
      },
      '&::after': {
        top: '-20px',
        right: '9px',
        position: 'relative',
        borderWidth: '1.7em 1.7em 1.7em 0',
        borderColor: 'transparent #a8a8a8 transparent transparent',
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      '&::before': {
        left: '6px',
        position: 'relative',
        borderWidth: '1.7em 0 1.7em 1.7em',
        borderColor: 'transparent transparent transparent #fff',
        zIndex: '999'
      },
      '&::after': {
        top: '-20px',
        left: '8px',
        position: 'relative',
        borderWidth: '1.7em 0 1.7em 1.7em',
        borderColor: 'transparent transparent transparent #a8a8a8',
      }
    }
  };
}

const styles = () => ({

  lightTooltip: {
    backgroundColor: "#fff",
    opacity: 1,
    border: '1px solid #a8a8a8',
    color: '#000000',
    fontSize: 12,
    minWidth: 350, 
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 9,

  },

  mobileTooltip: {
   backgroundColor: "#fff",
   opacity: 1,
    border: '1px solid #a8a8a8',
   color: '#000000',
   fontSize: 11,
    minWidth: 250,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 9,
 
  },

  smallTooltip: {
    minWidth: 180
  },

  tooltipTop: {
    margin:'-2px -1px'
  },

  tooltipLeft: {
    margin: '0'
  },

  backgroundWhite: {
    opacity: 1
  },
  arrowPopper: arrowGenerator(),

  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before, &::after': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  }
});


const Hint = ({ text, position, mobileTooltip, smallTooltip,  fillColor, classes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [arrowRef, setArrowRef] = useState(false);

  const handleTooltipClose = () => {
    setIsOpen(false);
  };

  const handleTooltipOpen = () => {
      setIsOpen(true);
  };


  const handleArrowRef = node => {
    setArrowRef(node);
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
            popperOptions: {
              modifiers: {
                arrow: {
                  enabled: arrowRef,
                  element: arrowRef,
                }
              }
            }
          }}
          title={
            <div>
              {text}
              <span className={classes.arrow} ref={handleArrowRef} />
            </div>
          }
          placement={position}
          onClose={handleTooltipClose}
          open={isOpen}
          disableFocusListener
          leaveTouchDelay={5000}
          classes={{
            tooltip: [!mobileTooltip ? classes.lightTooltip : classes.mobileTooltip,
              smallTooltip ? classes.smallTooltip : ''].join(' '),
            popper: classes.arrowPopper,
            tooltipPlacementTop: classes.tooltipTop, 
            tooltipPlacementLeft: classes.tooltipLeft
          }}
        >
          <IconButton
            disableRipple
            onClick={handleTooltipOpen}
            onMouseOver={handleTooltipOpen}
            style={{ backgroundColor: 'transparent' }}
          >
            <HintSVG fill={fillColor} />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

Hint.propTypes = {
  text: PropTypes.object.isRequired,
  position: PropTypes.string,
  width: PropTypes.string,
  fillColor: PropTypes.string,
  smallTooltip: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

Hint.defaultProps = {
  position: 'top',
  fillColor: variables.primaryColor,
};

export default withStyles(styles)(Hint);
