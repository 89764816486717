import 'react-app-polyfill/ie11';
import URLSearchParams from '@ungap/url-search-params';
import qs from 'query-string';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';

export const TOKEN_NAME = 'NATIONWIDE_CAREMATTERS_TOKEN';

class AuthHelper {
  isAuthenticated = () => {
    const token = this.getToken();
    return !_.isNil(token) && !!token && !this.isTokenExpired(token);
  };

  isTokenExpired = token => {
    try {
      const { exp } = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      // Checking if token is expired.
      if (exp < currentTime) {
        return true;
      }
      return false;
    } catch (err) {
      return true;
    }
  };

  getDataLayer = token => {
    const { anx_code, field_ods_key, field_salesforce_id } = jwtDecode(token);
    return {
      field_code: anx_code,
      field_ods_key: field_ods_key,
      field_salesforce_id: field_salesforce_id
    };
  };

  setToken = token => {
    // Saves user token to sessionStorage
    if (token) {
      sessionStorage.setItem(TOKEN_NAME, token);
    }
  };

  getToken = () => {
    let token;
    try {
      // Get token from url or sessionStorage
      const parsedURL = qs.parse(window.location.search);
      token = parsedURL.token || null;
    } catch (error) {
      const params = new URLSearchParams(window.location.search);
      token = params.get('token') || null;
    }
    const jwtToken = token || sessionStorage.getItem(TOKEN_NAME);

    if (jwtToken === 'null' || _.isEmpty(jwtToken)) {
      return null;
    }
    return jwtToken;
  };

  logout = () => {
    // Clear token from sessionStorage
    sessionStorage.removeItem(TOKEN_NAME);
  };
}

export default AuthHelper;
