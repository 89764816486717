/*
 * Header Messages
 *
 * This contains all the text for the Header component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.HomePage';

export default defineMessages({
  viewHistory: {
    id: `${scope}.viewHistory`,
    defaultMessage: 'View History',
  },
  newIllustration: {
    id: `${scope}.newIllustration`,
    defaultMessage: 'New Illustration',
  },
  welcomeAdvisor: {
    id: `${scope}.welcomeAdvisor`,
    defaultMessage: 'Welcome Advisor',
  },
  nationwideCareIllust: {
    id: `${scope}.nationwideCareIllust`,
    defaultMessage: 'Nationwide <span>CareMatters® Illustrator</span>',
  },
});
