/*
 * NotFoundPage Messages
 *
 * This contains all the text for the NotFoundPage container.
 */
import { defineMessages } from 'react-intl';

export const scope = 'app.containers.NotFoundPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: '404 Page not found',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'The page you requested is no longer available or cannot be found.',
  },
  instruction: {
    id: `${scope}.instruction`,
    defaultMessage: 'Please double-check the URL address you used.',
  },
  return: {
    id: `${scope}.return`,
    defaultMessage: 'Go back to <a href="/">Home Page</a>',
  },
});
