import styled from 'styled-components';
import variables from '../../theme/themeVars';

export const WizardContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 0px 24px;
`;
export const StyledStepsNavigation = styled.div`
  display: block;
  width: 100%;
`;
export const StyledStepsNavigationStep = styled.div`
  border-right: 1px solid #87898b;
  display: inline-block;
  font-size: 16px;
  padding: 2px 20px;
  margin: 3px auto 11px;
  text-align: center;
  font-family: ${variables.fontRegular};
  color: #b6b6b6;
  cursor: default;

  &.is-visited {
    cursor: pointer;
    color: #253746;
  }

  &.is-active {
    color: #1c57a5;
    cursor: pointer;
  }

  &:last-child {
    border: none;
  }

  @media only screen and (max-width: 600px) and (orientation: portrait) {
    font-size: 14px;
    padding: 0px 10px;
    border: none;
    display: block;
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    font-size: 14px;
    padding: 2px 14px;
  }
`;
export const StyledHeader = styled.div`
  color: ${variables.primaryColor};
  font-family: ${variables.fontHeadlineSemibold};
  font-size: 40px;
  font-weight: 300px;
  margin-left: -4px;
  margin-bottom: 28px;

  @media only screen and (max-width: 600px) {
    font-size: 40px;
  }
`;
export const StyledSummary = styled.div`
  border: 1px solid green;
  display: inline-block;
  height: 120px;
  vertical-align: top;
  width: 20%;
`;
export const StyledSummaryStep = styled.div`
  border: 1px solid green;
  display: block;
  height: 60px;
  vertical-align: top;

  &.is-active {
    background-color: #ccc;
  }
`;
export const StyledSteps = styled.div`
  border-top: 3px solid #003b5c;
  width: 100%;
`;
export const StyledStep = styled.div`
  display: inline-block;
  min-height: 300px;
  width: 100%;
  margin: 34px auto;
`;
export const StyledNavigationControls = styled.div`
  margin-bottom: 90px;

  button {
    min-width: 114px;
    padding: 9px 20px;
    margin-bottom: 10px;
    font-family: ${variables.fontMedium};

    &:first-child {
      min-width: 360px;
      margin-right: 24px;

      @media only screen and (max-width: 960px) {
        min-width: auto;
      }
    }
  }
`;
