import React, { useContext } from 'react';

// custom hook to create App context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(
      'App components cannot be rendered outside the FormWizard components',
    );
  }
  return context;
};

const AppContext = React.createContext();

export default AppContext;
