import styled from 'styled-components';
import MUIDataTable from 'mui-datatables';
import { Link } from '@material-ui/core/';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import variables from '../../theme/themeVars';

export const StyledTextField = styled(TextField)`
  display: block !important;
  margin-bottom: 0px !important;

  .error-date {
    input {
      border-color: red;
    }
  }

  input {
    padding: 26px 11px 2px !important;
  }

  @media (max-width: 569px) and (min-width: 461px) and (orientation: portrait) {
    width: 83%;
  }
`;

export const StyledPopper = styled(Popper)`
  z-index: 9999;
  width: auto;

  @media only screen and (max-width: 570px) and (orientation: portrait) {
    width: calc(100% - 13px);
    margin: 0px auto;
  }
`;

export const StyledPageButton = styled(IconButton)`
  color: #757575 !important;

  span {
    font-size: 0.9rem;
    padding: 12px;
  }

  &.current-page span {
    color: #337ab7 !important;
    cursor: default;
    font-weight: bold;
  }

  &.current-page:hover {
    background: none;
    cursor: default;
  }
`;

export const StyledPaper = styled(Paper)`
  padding: 0px 0px 0px 12px;
  font-family: ${variables.fontRegular};
  font-size: 13px;

  input::-ms-clear {
    display: none !important;
  }

  > div {
    display: inline-block;
    padding-top: 12px;

    > div {
      display: inline-block;
      max-width: none;
      @media only screen and (max-width: 570px) and (orientation: portrait) {
        width: 42%;
        position: relative;

        &:last-child {
          max-width: 16%;
        }
      }

      @media only screen and (max-width: 460px) and (orientation: portrait) {
        display: block;
        width: 99%;
        text-align: center;
        left: -7px;
        position: relative;

        &:last-child {
          max-width: none;
          top: -18px !important;
        }
      }

      &:last-child {
        top: -25px;
        left: -5px;
        position: relative;
      }
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  position: relative;
  top: 10px;
  left: -3px;
`;

export const StyledLink = styled(Link)`
  color: #337ab7 !important;
  &:hover {
    color: #23527c !important;
  }
`;
export const StyledHeader = styled.div`
  color: ${variables.primaryColor};
  font-family: ${variables.fontHeadlineSemibold};
  font-size: 46px;
  font-weight: normal;
  letter-spacing: -1.5px;

  @media only screen and (max-width: 959.95px) {
    margin: 0px 8px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 40px;
    margin-left: 5px;
  }
`;

export const SyledtHint = styled.div`
  top: calc(50% - 18px);
  position: absolute;
  right: 28px;
`;

export const Wrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 90px;
  #simple-popper {
    z-index: 999;
  }
  &.loading {
    table {
      tbody tr td {
        width: 0 !important;
        &:nth-child(odd) {
          width: 0 !important;
          padding: 0;
          display: none !important;
        }
      }
    }
  }
  @media only screen and (max-width: 959.95px) {
    margin: 60px 8px 90px;
  }
`;

export const StyledTable = styled(MUIDataTable)`
  box-shadow: none !important;

  &.active-filter {
    div {
      button.filter-icon {
        color: #337ab7;
      }
    }
  }

  input::-ms-clear {
    display: none;
  }

  @media only screen and (max-width: 959.95px) {
    margin: 0px 8px;
  }

  &.not-found {
    text-align: center;

    tbody {
      tr {
        td {
          @media only screen and (max-width: 959.95px) {
            width: auto;
            padding-top: 10px;

            &:nth-child(odd) {
              display: none;
             }
          }
        }
      }
    }
  }

  table {
      font-family: ${variables.fontRegular};
      width: 100%;
      border: 1px solid #d9d9d9 !important;

      thead {
        background-color: ${variables.viewHistoryTableHead};
        border: 1px solid ${variables.viewHistoryTableHead};
      }
    
      th {
        background-color: ${variables.viewHistoryTableHead};
        color:#fff;
        font-size: 0.85em;
        padding: 20px 5px 20px 20px;
        min-width: 91px;
        top: -1px;
        min-height: 57px;
        max-height: 57px;

        &:first-child {
          min-width: 170px;
        }

        &:last-child {
          min-width: 66px;
        }

        &:nth-last-child(2) {
          min-width: 132px;
        }

        > svg {
          font-size: 15px;
          margin: 0px 0px -3px 7px;
        }

        span {
           div {
             span {
               svg {
                    margin-left: 5px;
                    margin-right: 0px;
                    margin-top: 5px;
               }
             }
           }
        }
  
        &.details-th {
          text-align: left;
          font-weight: normal;
          top: -1px;
          left: 0px;
          z-index: 1000;
          position: sticky;
          > div {
            display: inline;
            top: auto;
            margin-top: -12px;
            right: auto;
         }

         button {
           padding: 12px 5px 0px;
           cursor: default;
           span {
            *, *::before, *::after {
              fill: white;
            }
           }
         }
        } 

      button {
        padding: 0px 10px;
      }
    }
    tbody {
      background-color: white;
      tr{

        @media only screen and (max-width: 959.95px) { 
          border: solid 1px rgba(0, 0, 0, 0.15);
          border-right: none;
          border-left: none;
          border-bottom: none;
          &:first-child {
            border-top: none;
          }

          &:last-child {
            border-bottom: solid 1px rgba(0, 0, 0, 0.15)
          }

          &:nth-child(odd):hover {
            background-color: rgb(248, 248, 248);;
           }

           &:nth-child(even):hover {
               background-color:rgb(253, 253, 253);
           }
        }

        td {
          height: auto;
          padding: 4px 5px 4px 20px;
          font-size: 0.85em;
          white-space: pre-wrap;

          &:nth-child(10) {
            word-break: break-word;
          }

          @media only screen and (min-width: 959.95px) {
            &:nth-child(10)  {
              max-width: 165px;
              min-width: 165px;
            }
          }

          @media only screen and (min-width: 1200px) {
            &:nth-child(10)  {
              max-width: 200px;
              min-width: 200px;
            }
          }

          @media only screen and (min-width: 1500px) {
            &:nth-child(10)  {
              max-width: 250px;
              min-width: 250px;
            }
          }

          &:last-child {
            text-align: right;
            padding-right: 20px;
          }

          @media only screen and (max-width: 600px) {
            font-size: 13px;
          }

          @media only screen and (max-width: 959.95px) {
            width: 70%;
            padding: 0px 10px;
            border-bottom: none;
            border-top: solid 1px rgba(0, 0, 0, 0.15);
            max-width: none;

            &:first-child {
              border-top: none;
            }

            &:last-child {
              text-align: left;
              padding-right: initial;
            }

            &:nth-child(odd) {
              width: 30%;
              padding: 15px 10px;
             }
             &:nth-child(even) {
              border: none;
             }
          }

       }

         &:nth-child(odd) {
          background-color: rgb(248, 248, 248);;
         }
         &:nth-child(even) {
             background-color:rgb(253, 253, 253);
         }
      }

     }
    }
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 333px;
  z-index: 999;
  left: 49%;
  @media only screen and (max-width: 959.95px) {
    top: 268px;
    left: 48%;
  }
  @media only screen and (max-width: 599px) {
    top: 253px;
  }
`;

export const DatesWrapper = styled.div`
  div {
    input {
      width: 100%;
      padding: 10px 12px 10px;
      color: rgba(0, 0, 0, 0.87);
      cursor: text;
      border: 1px solid #d9d9d9;
      display: inline-flex;
      font-size: 1rem;
      font-family: Helvetica Neue;
      line-height: 1.18;
      align-items: center;
      border-radius: 4px;
    }

    &.react-datepicker-popper {
      z-index: 1001;
    }
  }
`;
