/* eslint-disable no-console */

import { getYearsDateOfBirth } from '../../../utils/utils';

/**
 * Validate Birthday or Age field as required
 * @param {object} values
 * @param {object} productSettings
 * @param {object} errors
 * @return {object} Errors object
 */
export const ageValidator = (values, productSettings, errors) => {
  if (!values.ageValidation) {
    errors.ageValidation = true;
    return errors;
  }

  errors = birthdayFieldValidator(values, productSettings, errors);
  errors = ageFieldValidator(values, productSettings, errors);

  return errors;
};

/**
 * Validate birthday field has a valid format and allowed data
 * @param {object} values
 * @param {object} productSettings
 * @param {object} errors
 * @return {object} Errors object
 */
const birthdayFieldValidator = (values, productSettings, errors) => {
  if (values.ageValidation !== 'birthday') {
    return errors;
  }

  if (!values.birthday) {
    errors.birthday = 'Enter a valid birthday';
    return errors;
  }

  if (
    !values.birthday.match(
      /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/,
    )
  ) {
    errors.birthday = 'Enter a valid birthday';
    return errors;
  }

  if (getYearsDateOfBirth(values.birthday) < productSettings.MinAge) {
    errors.birthday = `Enter a valid birthday, minimum age is ${
      productSettings.MinAge
    }`;
    return errors;
  }

  if (getYearsDateOfBirth(values.birthday) > productSettings.MaxAge) {
    errors.birthday = `Enter a valid birthday, maximum age is ${
      productSettings.MaxAge
    }`;
    return errors;
  }
    return errors;
};

/**
 * Validate issyeAge field has a valid format and allowed data
 * @param {object} values
 * @param {object} productSettings
 * @param {object} errors
 * @return {object} Errors object
 */
const ageFieldValidator = (values, productSettings, errors) => {
  if (values.ageValidation !== 'issueAge') {
    return errors;
  }

  if (!values.issueAge) {
    errors.issueAge = true;
    return errors;
  }

  if (values.issueAge < productSettings.MinAge) {
    errors.issueAge = `Enter a valid age, minimum age is ${
      productSettings.MinAge
    }`;
    return errors;
  }

  if (values.issueAge > productSettings.MaxAge) {
    errors.issueAge = `Enter a valid age, maximum age is ${
      productSettings.MaxAge
    }`;
    return errors;
  }

  return errors;
};
