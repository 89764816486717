import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Grid } from '@material-ui/core/';

import AppContext from './AppContext';
import HomePage from '../HomePage';
import NotFoundPage from '../../components/NotFoundPage';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AuthHelper from '../../utils/auth';
import {
  inIframe,
  getCleanApiURL,
  removeParamFromURL,
  getIdFromUrl,
} from '../../utils/utils';
import IllustrationHistory from '../IllustrationHistory';
import { getConfiguration } from '../../utils/api';
import TagManager from 'react-gtm-module';

const auth = new AuthHelper();

const App = () => {
  const [configuration, setConfiguration] = useState({
    featureFlip: {},
    serverDateTime: null,
    user: {
      preparedBy: '',
    },
  });
  const [illustrationId, setIllustrationId] = useState();
  const isAuthenticated = auth.isAuthenticated();
  // Validate user authentication
  if (!isAuthenticated) {
    window.location.href = getCleanApiURL();
    return null;
  }
  const token = auth.getToken();
  window.history.replaceState(
    null,
    null,
    removeParamFromURL('token', window.location.href),
  );

  useEffect(() => {
    auth.setToken(token);
  }, [token]);

  // send message to parent when is iframe
  const isIframe = inIframe();
  useEffect(() => {
    if (isIframe) {
      window.parent.postMessage('Get new access token', '*');
    } else {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
        dataLayer: auth.getDataLayer(token)
      };
      TagManager.initialize(tagManagerArgs);

      setIllustrationId(getIdFromUrl());
      getConfiguration().then(result => {
        const serverDateTime = new Date(result.data.serverDateTime);
        result.data.serverDateTime = serverDateTime;
        setConfiguration(result.data);
      });
    }
  }, []);

  return isAuthenticated && !isIframe ? (
    <AppContext.Provider
      value={{
        isAuthenticated,
        token,
        configuration,
        illustrationId,
      }}
    >
      <React.Fragment>
        <CssBaseline />
        <Header />
        {/* <Content> */}
        <Grid container spacing={0} alignItems="center" justify="center">
          <Grid item xs={12} sm={10} md={9} lg={8} xl={6}>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/illustration/:id(\d+)" component={HomePage} />
              <Route
                exact
                path="/view-history"
                component={IllustrationHistory}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </Grid>
        </Grid>
        {/* </Content> */}
        <Footer />
      </React.Fragment>
    </AppContext.Provider>
  ) : null;
};

export default App;
