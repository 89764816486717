import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableFooter from '@material-ui/core/TableFooter';
import MuiTablePagination from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import IconButton from '@material-ui/core/IconButton';
import { StyledPageButton } from './styles';

const defaultPaginationStyles = {
  root: {
    '&:last-child': {
      display: 'block',
      padding: '0px 24px 0px 24px',
      '@media screen and (max-width: 905px)': {
        textAlign: 'right',
        padding: '0px 15px',
      },
      '& >div': {
        padding: 0,
        '@media screen and (max-width: 905px)': {
          margin: '10px 0px 0px',
          display: 'inline-table',
          height: 'auto',
          minHeight: 'auto',
        },
        '& input': {},
      },
    },
  },
  toolbar: {
    '& span': {
      cursor: 'default',
      margin: '0px 10px',
      '@media screen and (max-width: 905px)': {
        margin: '10px 10px 0px',
      },
    },
    '& div': {
      margin: 0,
      '& svg': {
        top: 4,
      },
      '& button': {
        padding: 0,
        height: 30,
        width: 30,
        '& span': {
          margin: '0px 4px',
          padding: 0,
          '@media screen and (max-width: 465px)': {
            margin: 0,
            '& svg': {
              width: '0.8em',
            },
          },
          cursor: 'pointer',
        },
      },
    },
    '& >span, >div': {
      '@media screen and (max-width: 905px)': {
        display: 'inline-block',
      },
    },
  },
  selectRoot: {
    marginRight: '0px',
    marginLeft: '0px',
    '& select': {
      width: 'calc(100% - 24px)',
      fontSize: 16,
      minWidth: 35,
    },
  },
};

const StylesPagination = {
  root: {
    flexShrink: 0,
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'block',
  },
};

let TablePaginationActionsApproach = ({
  classes,
  count,
  page,
  rowsPerPage,
  onChangePage,
}) => {
  const totalOfPages = Math.max(0, Math.ceil(count / rowsPerPage));

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleGoToPageButtonClick = (event, pageNumber) => {
    onChangePage(event, pageNumber - 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, totalOfPages - 1);
  };

  const pageNumbersGenerator = current => {
    const totalOfPagesToDisplay = 2;
    const left = current - totalOfPagesToDisplay;
    const right = current + totalOfPagesToDisplay;
    const range = [];

    for (let i = 1; i <= totalOfPages; i += 1) {
      if (i >= left && i <= right) {
        range.push(i);
      }
    }
    return range;
  };

  const getPageNumberElement = (pageNumber, currentPage) => {
    const normalizedPageNumber = pageNumber - 1;

    return (
      <StyledPageButton
        key={`pageNumber-${pageNumber}`}
        className={normalizedPageNumber === currentPage ? 'current-page' : ''}
        disableRipple={normalizedPageNumber === currentPage}
        onClick={event => {
          if (normalizedPageNumber !== currentPage) {
            handleGoToPageButtonClick(event, pageNumber);
          }
        }}
      >
        {pageNumber}
      </StyledPageButton>
    );
  };

  const pageNumbers = pageNumbersGenerator(page + 1, totalOfPages);

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        <KeyboardArrowLeft />
      </IconButton>

      {pageNumbers.map(pageNumber => getPageNumberElement(pageNumber, page))}

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

TablePaginationActionsApproach.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

TablePaginationActionsApproach = withStyles(StylesPagination)(
  TablePaginationActionsApproach,
);

const TablePagination = ({
  count,
  classes,
  options,
  rowsPerPage,
  page,
  changePage,
  changeRowsPerPage,
}) => (
  <MuiTableFooter>
    <MuiTableRow>
      <MuiTablePagination
        className={classes.root}
        classes={{
          caption: classes.caption,
          toolbar: classes.toolbar,
          selectRoot: classes.selectRoot,
        }}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={options.textLabels.pagination.rowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          `Showing ${from} to ${to} of ${count} entries`
        }
        SelectProps={{
          id: 'pagination-input',
          native: true,
          SelectDisplayProps: { id: 'pagination-rows' },
          MenuProps: {
            id: 'pagination-menu',
            MenuListProps: { id: 'pagination-menu-list' },
          },
        }}
        rowsPerPageOptions={options.rowsPerPageOptions}
        onChangePage={(_, pageNumber) => changePage(pageNumber)}
        onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
        ActionsComponent={TablePaginationActionsApproach}
        // ActionsComponent={TablePaginationActions_bak}
      />
    </MuiTableRow>
  </MuiTableFooter>
);

TablePagination.propTypes = {
  /** Total number of table rows */
  count: PropTypes.number.isRequired,
  /** Options used to describe table */
  options: PropTypes.object.isRequired,
  /** Current page index */
  page: PropTypes.number.isRequired,
  /** Total number allowed of rows per page */
  rowsPerPage: PropTypes.number.isRequired,
  /** Callback to trigger rows per page change */
  changeRowsPerPage: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

export default withStyles(defaultPaginationStyles, {
  name: 'MUIDataTablePagination',
})(TablePagination);
