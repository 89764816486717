import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import FilterIcon from '@material-ui/icons/FilterList';
import { withStyles } from '@material-ui/core/styles';

const defaultToolbarStyles = {
  iconButton: {},
};

const CustomToolbar = ({
  classes,
  handleOnDownload,
  handleOnFilter,
  ariaDescribedby,
  variant,
}) => (
  <React.Fragment>
    <Tooltip title="Download CSV">
      <IconButton
        aria-label="Download CSV"
        classes={{ root: classes.icon }}
        onClick={handleOnDownload}
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title="Filter by date">
      <IconButton
        id="filtersOption"
        aria-label="Filter"
        classes={{ root: classes.icon }}
        onClick={handleOnFilter}
        aria-describedby={ariaDescribedby}
        variant={variant}
        className="filter-icon"
      >
        <FilterIcon />
      </IconButton>
    </Tooltip>
  </React.Fragment>
);

CustomToolbar.propTypes = {
  classes: PropTypes.object,
  handleOnDownload: PropTypes.func,
  handleOnFilter: PropTypes.func,
  ariaDescribedby: PropTypes.string,
  variant: PropTypes.string,
};

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(
  CustomToolbar,
);
