import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core/';

const styles = () => ({
  root: {
    flexGrow: 1,
    minHeight: 70,
    cursor: 'default',
  },
  footer: {
    backgroundColor: 'rgb(61, 64, 69)',
    lineHeight: '16.22px',
    color: 'rgb(221, 221, 221)',
    fontSize: '12px',
    fontFamily: '"Gotham-Book", "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  marginTop: {
    marginTop: '40px',
  },
  marginBottom: {
    marginBottom: '100px',
  },
  rightsReserved: {
    backgroundColor: 'rgb(67, 71, 77)',
    color: 'rgb(187, 187, 187)',
    fontSize: '12px',
    fontFamily: '"Gotham-Book", "Helvetica Neue", Helvetica, Arial, sans-serif',
    minHeight: '69px',
    height: '69px',
  },
});

const FooterBar = ({ classes }) => {
  return (
    <div color="primary" className={classes.appBar}>
      <Grid
        container
        className={classes.footer}
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={11} sm={10} md={9} lg={8} xl={6}>
          <p className={classes.marginTop}>
            Guarantees are backed by the claims-paying ability of the issuing
            insurance company. The financial strength of Nationwide is not
            intended to reflect the investment experience of a variable account
            which is subject to market risk.
          </p>
          <p>
            When choosing a product, make sure that life insurance and long-term
            care insurance needs are met. Be sure to choose a product that meets
            long-term life insurance needs, especially if personal situations
            change — for example, marriage, birth of a child or job promotion.
            Weigh the costs of the policy, and understand that life insurance,
            and long-term care coverage linked to life insurance, has fees and
            charges that vary with sex, health, age and tobacco use. Riders that
            customize a policy to fit individual needs usually carry an
            additional charge.
          </p>
          <p>
            Keep in mind that the payment of long-term care rider benefits, as
            an acceleration of the death benefit, will reduce both the death
            benefit and cash surrender values of the policy. Additionally, loans
            and withdrawals will also reduce both the cash values and the death
            benefit. Care should be taken to make sure that life insurance needs
            continue to be met even if the rider pays out in full, or after
            money is taken from the policy. There is no guarantee that the rider
            will cover the entire cost for all of the insured's long-term care,
            as this may vary with the needs of each insured. Nationwide pays the
            long-term care benefit to the policy owner; there is no guarantee
            the policy owner will use the benefit for long-term care expenses if
            the policy is owned by someone other than the insured.
          </p>
          <p>
            Products are issued by Nationwide Life Insurance Company or
            Nationwide Life and Annuity Insurance Company, Columbus, Ohio.
          </p>
          <p>
            Nationwide, its affiliates, and subsidiaries are not related to or
            affiliated with Annexus or its affiliates and subsidiaries.
          </p>
          <p>
            Nationwide, Nationwide N and Eagle, and Nationwide is on your side
            are service marks of Nationwide Mutual Insurance Company. © 2019
            Nationwide
          </p>
          <p className={classes.marginBottom}>
            FLM-1253AO-XS (05/19) <br />
            FLM-1254AO-XS (CA) (05/19)
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.rightsReserved}
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={11} sm={10} md={9} lg={8} xl={6}>
          <div>
            © 2019 Nationwide Mutual Insurance Company | All Rights Reserved
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

FooterBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FooterBar);
