export const getYearsDateOfBirth = date => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

export const removeCurrencyFormat = value => value.replace(/[^0-9.-]+/g, '');

export const getCurrencyFormat = value => {
  value += '';
  const x = value.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `${'$1'}${','}${'$2'}`);
  }
  return `$${x1}${x2}`;
};

export const isSafari =
  /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const removeParamFromURL = (key, sourceURL) => {
  let rtn = sourceURL.split('?')[0];
  let param;
  let paramsArr = [];

  const queryString =
    sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    paramsArr = queryString.split('&');
    for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
      param = paramsArr[i].split('=')[0];
      if (param === key) {
        paramsArr.splice(i, 1);
      }
    }
    if (paramsArr.length > 0) {
      rtn = `${rtn}?${paramsArr.join('&')}`;
    }
  }
  return rtn;
};

export const getCleanApiURL = () => {
  const urlWithoutToken = removeParamFromURL('token', window.location.href);
  return `${process.env.REACT_APP_SAML_SSO_URL}${encodeURIComponent(
    urlWithoutToken,
  )}`;
};

export const bindEvent = (element, eventName, eventHandler) => {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent(`on${eventName}`, eventHandler);
  }
};

export const removeEvent = (element, eventName, eventHandler) => {
  if (element.removeEventListener) {
    element.removeEventListener(eventName, eventHandler, false);
  } else if (element.detachEvent) {
    element.detachEvent(`on${eventName}`, eventHandler);
  }
};

export const getIdFromUrl = () => {
  const path = window.location.pathname;
  return path.split('/')[2];
};

export const getFirstName = value => {
  const values = value.split(' ');
  return values[0];
};

export const getMiddleName = value => {
  const values = value.split(' ');
  if (values.length > 2) {
    return values[1];
  }
  return '';
};

export const getLastName = value => {
  const values = value.split(' ');
  if (values.length >= 3) {
    let finalValue = '';
    // eslint-disable-next-line no-plusplus
    for (let x = 2; x < values.length; x++) {
      finalValue += `${values[x]} `;
    }
    return finalValue.trim();
  }
  return values[1];
};

export const toQueryString = params =>
  Object.keys(params)
    .filter(key => params[key] !== '')
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

export const createCSVDownload = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv' });

  /* taken from react-csv */
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const dataURI = `data:text/csv;charset=utf-8,${csv}`;

    const URL = window.URL || window.webkitURL;
    const downloadURI =
      typeof URL.createObjectURL === 'undefined'
        ? dataURI
        : URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', downloadURI);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

/**
 * Get numbers and decimals from a formatted value, otherwise returns null
 * @param {string} value Formatted value
 */
export const getNumbersAndDecimalsOnly = value => {
  return typeof value === 'string'
    ? (value.match(/\d*[.]?/g) || []).join('')
    : null;
};

export const splitDecimal = (numStr, allowNegative = true) => {
  const hasNagation = numStr[0] === '-';
  const addNegation = hasNagation && allowNegative;
  const numStrAbsolute = numStr.replace('-', '');

  const parts = numStrAbsolute.split('.');
  const beforeDecimal = parts[0];
  const afterDecimal = parts[1] || '';

  return {
    beforeDecimal,
    afterDecimal,
    hasNagation,
    addNegation,
  };
};

export const formatNumberWithDecimals = (numStr, scale = 2) => {
  const numberAsStr = `${numStr}`;
  // split decimals
  let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(
    numberAsStr,
    true,
  );
  // appends zeros
  if (afterDecimal) {
    afterDecimal = limitToScale(afterDecimal, scale, true);
  }
  // has decimal
  const hasDecimalSeparator = numberAsStr.indexOf('.') !== -1 && !!afterDecimal;
  // add negation
  if (addNegation) beforeDecimal = `-${beforeDecimal}`;
  // thousands format
  beforeDecimal = applyThousandSeparator(beforeDecimal, ',');
  return `${beforeDecimal}${hasDecimalSeparator ? '.' : ''}${afterDecimal}`;
};

export const limitToScale = (numStr, scale, fixedDecimalScale) => {
  let str = '';
  const filler = fixedDecimalScale ? '0' : '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= scale - 1; i++) {
    str += numStr[i] || filler;
  }
  return str;
};

export const applyThousandSeparator = (str, thousandSeparator) => {
  const thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g;
  let index = str.search(/[1-9]/);
  index = index === -1 ? str.length : index;
  return (
    str.substring(0, index) +
    str
      .substring(index, str.length)
      .replace(thousandsGroupRegex, `$1${thousandSeparator}`)
  );
};
