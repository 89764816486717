import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import variables from '../../theme/themeVars';
import {
  Grid,
  Toolbar,
  Typography,
  IconButton,
  Link,
} from '@material-ui/core/';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import styled from 'styled-components';
import WhiteLogo from '../../images/logo-white.svg';
import messages from './messages';

export const MenuStyle = styled(Menu)`
  li {
    background-color: white !important;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08) !important;
    }
  }
  p {
    margin: 0px;
  }
`;

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: 70,
    cursor: 'default',
  },
  appBar: {
    backgroundColor: `${variables.primaryColor}`,
    lineHeight: '28.08px',
  },
  toolBar: {
    minHeight: 0,
  },
  title: {
    flexGrow: 1,
    fontFamily: `${variables.fontMedium}`,
    fontSize: 16,
    '& span': {
      display: 'inline-block;',
    },
  },
  menuButton: {
    marginLeft: -12,
    paddingTop: 7,
    float: 'right',
    '&:hover': {
      backgroundColor: 'inherit',
      cursor: 'pointer',
    },
  },
  links: {
    fontFamily: `${variables.fontRegular}`,
    fontSize: 15,
    marginLeft: 20,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileMenu: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  buttonLink: {
    display: 'block',
  },
});

const Header = ({ classes }) => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const currentPageName = window.location.pathname;

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const isViewHistoryModule = currentPageName === '/view-history';
  const isNewIllustrationModule = currentPageName === '/';

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const renderMobileMenu = (
    <MenuStyle
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.mobileMenu}
    >
      {!isViewHistoryModule && (
        <MenuItem onClick={handleMobileMenuClose}>
          <Link color="inherit" href="/view-history">
            <Typography color="inherit" className={classes.links}>
              <FormattedMessage {...messages.viewHistory} />
            </Typography>
          </Link>
        </MenuItem>
      )}

      {!isNewIllustrationModule && (
        <MenuItem onClick={handleMobileMenuClose}>
          <Link color="inherit" href="/">
            <Typography color="inherit" className={classes.links}>
              <FormattedMessage {...messages.newIllustration} />
            </Typography>
          </Link>
        </MenuItem>
      )}
    </MenuStyle>
  );
  return (
    <AppBar position="static" color="primary" className={classes.appBar}>
      <Grid
        container
        className={classes.root}
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Grid item xs md={2}>
          <div className={classes.logo}>
            <a href="/" className={classes.buttonLink}>
              <IconButton color="inherit" className={classes.menuButton}>
                <img src={WhiteLogo} alt="" />
              </IconButton>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Toolbar className={classes.toolBar}>
            <Typography variant="h6" color="inherit" className={classes.title}>
              <FormattedHTMLMessage {...messages.nationwideCareIllust} />
            </Typography>
            <div className={classes.sectionDesktop}>
              {!isViewHistoryModule && (
                <Link color="inherit" href="/view-history">
                  <Typography color="inherit" className={classes.links}>
                    <FormattedMessage {...messages.viewHistory} />
                  </Typography>
                </Link>
              )}

              {!isNewIllustrationModule && (
                <Link color="inherit" href="/">
                  <Typography color="inherit" className={classes.links}>
                    <FormattedMessage {...messages.newIllustration} />
                  </Typography>
                </Link>
              )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </Grid>
        <Grid item xs md={2}>
          <div className={classes.sectionDesktop}>
            <Typography color="inherit" className={classes.links}>
              <FormattedMessage {...messages.welcomeAdvisor} />
            </Typography>
          </div>
        </Grid>
      </Grid>
      {renderMobileMenu}
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
