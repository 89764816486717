import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export const StyledList = styled(List)`
  && {
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    li {
      padding-left: 20px;
      padding-right: 20px;
      cursor: pointer;

      > div {
        display: contents;

        > span {
          display: inline-block;
          margin-left: -16px;
          font-size: 0.97em;
          letter-spacing: 0.01071em;
          margin-right: 16px;
        }
      }

      &:last-child {
        > div {
          display: block;
        }
      }
    }

    &.open-border {
      border-radius: 4px 4px 0px 0px;
      border-bottom: none;
    }
  }
`;

export const StyledBackgroundGrid = styled(Grid)`
  && {
    border: 1px solid #d9d9d9;
    border-radius: 0 0 4px 4px;
    padding: 21px 20px 8px;
    background-color: #fafafa;
  }

  > div {
    padding-right: 20px;

    p {
      margin: 0px 0px 10px 12px;
    }

    &:last-child {
      margin-top: 4px;
    }
  }
`;

export const ErrorMessageMask = styled(ErrorMessage)`
  position: absolute;
  margin-top: -10px;
  margin-left: 12px;
  color: #e32c2b;
  font-family: 'Helvetica Neue';
  font-size: 0.7em;
  letter-spacing: 0.15px;
`;

export const TextFieldStyle = styled(TextField)`
  && {
    input {
      font-size: 0.97em;
      padding-top: 22px;
    }
  }
`;

export const PopupWarningText = styled.div`
  font-size: 1em;
  font-family: Gotham-Book;
  color: #1c57a5;
  line-height: 1.33;
  margin-top: 8px;
  margin-bottom: -29px;
`;
