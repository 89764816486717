const catalog = {
  genders: [
    { value: 'Male', name: 'Male' },
    { value: 'Female', name: 'Female' },
  ],
  approvedStates: [
    { value: 'CA', name: 'California' },
    { value: 'AZ', name: 'Arizona' },
    { value: 'AK', name: 'Arkansas' },
  ],
  counties: [
    { value: 'SD', name: 'San Diego' },
    { value: 'LA', name: 'Los Angeles' },
    { value: 'FR', name: 'Fresno' },
  ],
  ageValidations: [
    { value: 'birthday', name: 'Birthday' },
    { value: 'issueAge', name: 'Issue Age' },
  ],
  illustrationType: [
    { value: 'Guaranteed Interest, Guaranteed Charges', name: 'Guaranteed Interest, Guaranteed Charges' },
  ],
  premiumModes: [
    { value: 'Annual', name: 'Annual' },
    { value: 'Monthly', name: 'Monthly' }
  ]
};

export const getApprovedStates = () => catalog.approvedStates;
export const isCareMattersOne = () => false;
export const isCareMattersTwo = () => true;

export default catalog;
