/**
 * Validate birthday field has a valid format and allowed data
 * @param {object} values
 * @return {object} Errors object
 */
export const OutputValidations = values => {
  const errors = {};
  const numbers = values.yearsForMonthlyOutput.split('-').map(Number);

  if (values.monthlyCost) {
    if (!values.illustrationType) {
      errors.illustrationType = true;
    }

    if (!values.yearsForMonthlyOutput) {
      errors.yearsForMonthlyOutput = true;
    }

    if (numbers[0] > numbers[1]) {
      errors.yearsForMonthlyOutput = true;
    }

    if (numbers[0] === 0 || numbers[1] === 0) {
      errors.yearsForMonthlyOutput = true;
    }
  }

  if (!values.preparedBy) {
    errors.preparedBy = true;
  } else {
    if (values.preparedBy.length > 100) {
      errors.preparedBy = 'Maximum 100 characters';
    }
    if (!/\s/.test(values.preparedBy)) {
      errors.preparedBy = "First name and last name required.";
    } else {
      const splitValue = values.preparedBy.split(" ");
      if (!splitValue[1]) {
        errors.preparedBy = "First name and last name required."
      }
    }
  }
  return errors;
};
