import { createMuiTheme } from '@material-ui/core/styles';
import variables from './themeVars';

const defaultTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

export default createMuiTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
    primary: {
      main: variables.primaryColor,
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff4081',
      main: variables.secondaryColor,
      dark: variables.nextFormBtnHover,
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: variables.headerSecondary,
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        textShadow: 'none',
        boxShadow: 'none',
      },
      root: {
        fontSize: '16px',
        fontWeight: 'unset',
        textTransform: 'none',
      },
    },
    MuiTypography: {
      root: {
        fontFamily: variables.fontRegular,
        fontSize: 15,
      },
      body2: {
        fontFamily: variables.fontRegular,
        fontSize: 15,
      },
      h5: {
        marginBottom: '1.35em',
        fontFamily: variables.fontRegular,
      },
      subtitle1: {
        fontFamily: variables.fontRegular,
      },
      caption: {
        fontFamily: variables.fontRegular,
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: 15,
        fontFamily: variables.fontRegular,
      },
    },
    MuiFilledInput: {
      root: {
        '&$error': {
          border: '1px solid red',
        },
      },
      input: {
        paddingBottom: '0.35714em',
        paddingTop: '1.5em',
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: variables.fontRegular,
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        lineHeight: 1.18,
        '&$focused': {
          border: '1px solid #2A9FD8',
        },
      },
      input: {
        backgroundColor: 'white',
        borderRadius: '4px',
        color: '#333333',
        fontSize: '15px',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: variables.fontRegular,
        color: '#737373;',
        fontSize: '15px',
        '&$focused': {
          color: variables.inputLabel,
          transform: 'translate(12px, 10px) scale(0.75) !important',
        },
        '&$shrink': {
          color: variables.inputLabel,
        },
        '&$disabled': {
          color: variables.inputLabel,
        },
        '&$filled': {
          transform: 'translate(12px, 18px) scale(1)',
        },
      },
    },
    MuiSelect: {
      select: {
        borderRadius: '4px',
        '&$filled': {
          backgroundColor: 'white',
          borderRadius: '4px',
        },
        '&$disabled': {
          color: '#b6b6b6 !important',
        },
      },
      icon: {
        fill: '#919191',
      },
    },
    MuiSvgIcon: {
      root: {
        fontFamily: 'Gotham-BookItalic',
      },
      colorPrimary: {
        color: variables.primaryColor,
      },
      colorSecondary: {
        color: '#919191',
      },
    },
    MuiCheckbox: {
      root: {
        fontFamily: variables.fontRegular,
        padding: '12px 8px 12px 12px',
      },
      colorPrimary: {
        color: variables.primaryColor,
        '&$disabled': {
          opacity: 0.5,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontFamily: 'Gotham-Book',
        backgroundColor: 'white',
        color: '#000000',
        border: '1px solid #000000',
        fontSize: 12,
        padding: '5px 7px',
      },
      popper: {
        opacity: 1,
      },
    },
    MuiList: {
      root: {
        fontFamily: variables.fontRegular,
      },
      padding: {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    MuiListItem: {
      root: {
        fontFamily: variables.fontRegular,
        paddingBottom: 0,
        paddingTop: 0,
        minHeight: 48,
      },
    },
    MuiSnackbarContent: {
      root: {
        paddingRight: 48,
        backgroundColor: 'rgba(52, 96, 148, 1)',
      },
    },
    MuiIconButton: {
      root: {
        padding: '12px 10px',
      },
    },
    MUIDataTableHeadCell: {
      sortActive: {
        color: '#fff',
      },
    },
    MuiTableSortLabel: {
      active: {
        color: '#fff !important',
      },
    },
    MUIDataTableSearch: {
      main: {
        display: 'flex',
        flex: '1 0 auto',
        '& button': {
          marginTop: '-4px',
        },
      },
      searchIcon: {
        display: 'none',
        marginTop: '15px',
        marginRight: '8px',
      },
      searchText: {
        flex: '0.8 0',
        top: 2,
        margin: '-1px 0 0px',
        '& div': {
          '&::before, &::after': {
            borderBottom: 0,
          },
        },
        '& div:hover': {
          '&::before, &::after': {
            border: 'none !important',
          },
        },
        '& input': {
          padding: '10px',
        },
        [defaultTheme.breakpoints.down('xs')]: {
          flex: '1 0',
        },
      },
      clearIcon: {
        marginLeft: '5px',
        '&:hover': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MUIDataTable: {
      paper: {
        '&&.iconSearchActive': {
          '& button[title=Search]': {
            color: '#337ab7 !important',
          },
        },
        '&&.iconSearchClose': {
          '& button[title=Search]': {
            color: 'rgba(0, 0, 0, 0.54) !important',
          },
        },
      },
    },
    MUIDataTableToolbar: {
      root: {
        maxHeight: 56,
        padding: 0,
        marginBottom: 10,
      },
      actions: {
        textAlign: 'right',
      },
      icon: {
        '&:hover': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
      [defaultTheme.breakpoints.down('xs')]: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        left: {
          padding: ' 0',
          flexGrow: ' 1',
          flex: ' 1',
        },
        titleText: {
          textAlign: 'center',
        },
        actions: {
          textAlign: 'right',
          display: 'flex',
          flexGrow: 0,
        },
      },
      [defaultTheme.breakpoints.down('sm')]: {
        left: {
          display: 'block',
          padding: '0',
        },
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});
