import React from 'react';
import { Field } from 'formik';
import { useResize } from 'react-browser-hooks';
import {
  injectIntl,
  intlShape,
  FormattedHTMLMessage,
  FormattedMessage,
} from 'react-intl';
import Grid from '@material-ui/core/Grid';

import { Typography } from '@material-ui/core';
import CurrencyField from '../../../components/CurrencyField';
import SelectField from '../../../components/SelectField';
import CheckBoxComponent from '../../../components/CheckBoxComponent';
import { StyledList, StyledBackgroundGrid } from '../styles';
import messages from '../messages';
import { useWizardContext } from '../../../components/FormWizard/WizardContext';
import { getYearsDateOfBirth } from '../../../utils/utils';
import {
  GetBenefitsType,
  GetScheduledPremiumOptions,
  GetBenefitDurations,
  GetRefundOfPremiumOptions,
  GetInflationBenefitOptions,
  GetPremiumModeOptions,
} from '../validators/catalogValidators';
import ListItemCheckboxFormik from '../../../components/ListItemCheckboxFormik';

const getCurrentAge = values => {
  if (values.ageValidation === 'issueAge') {
    return values.issueAge;
  }
  return getYearsDateOfBirth(values.birthday);
};

const ForceAmountAndPremium = ({ intl: { formatMessage } }) => {
  const {
    productSettings,
    values,
    changeOnForm,
    updateInflationBenefitOptionForNY,
  } = useWizardContext();

  const { width } = useResize();
  const age = getCurrentAge(values);
  const careMatterII = productSettings.IsCareMattersII;
  const isNotAvailableForNY = !!(
    values.issueState === 'NY' &&
    (values.benefitDuration === '2' || values.benefitDuration === '3')
  );
  const BenefitsType = GetBenefitsType(productSettings);
  const ScheduledPremiumOptions = GetScheduledPremiumOptions(
    productSettings,
    age,
    values,
    changeOnForm,
  );
  const BenefitDurations = GetBenefitDurations(
    productSettings,
    values.premiumOrBenefitType,
    values,
    age,
    changeOnForm,
  );
  const RefundOfPremiumOptions = GetRefundOfPremiumOptions(
    productSettings,
    age,
    values.inflationBenefitOption,
    values.refundOfPremium,
    changeOnForm,
  );
  const PremiumModeOptions = GetPremiumModeOptions(
    values.scheduledPremium,
    values.premiumMode,
    changeOnForm,
  );
  const InflationBenefitOptions = GetInflationBenefitOptions(
    productSettings,
    age,
    values,
    changeOnForm,
  );
  const defaultBanefitDurationAvailable =
    BenefitDurations.filter(i => i.value === '6').length > 0;

  const disabledDropdown = options => options.length < 2;

  return (
    <React.Fragment>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <SelectField
            name="premiumOrBenefitType"
            label={formatMessage(messages.premiumOrBenefitType)}
            options={BenefitsType}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencyField
            name="premiumAmount"
            label={formatMessage(messages.premiumAmount)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={careMatterII ? 4 : 6}>
          <SelectField
            name="scheduledPremium"
            label={formatMessage(messages.scheduledPremium)}
            showHelp={!disabledDropdown(ScheduledPremiumOptions)}
            hintPosition={width < 600 ? 'left' : 'top'}
            mobileTooltip={width < 600}
            textHint={
              careMatterII ? (
                <FormattedHTMLMessage {...messages.scheduledPremiumHint} />
              ) : (
                <FormattedHTMLMessage {...messages.scheduledPremiumHintCMI} />
              )
            }
            options={ScheduledPremiumOptions}
            disabled={disabledDropdown(ScheduledPremiumOptions)}
          />
        </Grid>
        {careMatterII && (
          <Grid item xs={12} sm={6} md={4}>
            <SelectField
              name="refundOfPremium"
              label={formatMessage(messages.refundOfPremium)}
              showHelp={!disabledDropdown(RefundOfPremiumOptions)}
              textHint={
                <FormattedHTMLMessage {...messages.refundOfPremiumHint} />
              }
              hintPosition={width > 993 ? 'top' : 'left'}
              mobileTooltip={width < 769}
              options={RefundOfPremiumOptions}
              disabled={disabledDropdown(RefundOfPremiumOptions)}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={careMatterII ? 4 : 6}>
          <SelectField
            name="premiumMode"
            label={formatMessage(messages.premiumMode)}
            showHelp={!disabledDropdown(PremiumModeOptions)}
            hintPosition={width > 993 ? 'top' : 'left'}
            mobileTooltip={width < 600}
            textHint={<FormattedHTMLMessage {...messages.premiumModeHint} />}
            options={PremiumModeOptions}
            disabled={disabledDropdown(PremiumModeOptions)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectField
            name="benefitDuration"
            showHelp={!disabledDropdown(BenefitDurations)}
            hintPosition={width > 762 ? 'top' : 'left'}
            mobileTooltip={width < 600}
            smallTooltip
            textHint={
              !defaultBanefitDurationAvailable ? (
                <FormattedMessage
                  {...messages.benefitDurationWithoutDefaultHint}
                />
              ) : (
                <FormattedMessage {...messages.benefitDurationHint} />
              )
            }
            label={formatMessage(messages.benefitDuration)}
            options={BenefitDurations}
            handleOnChange={event => {
              updateInflationBenefitOptionForNY(
                event.target.value,
                values.issueState,
              );
            }}
            disabled={disabledDropdown(BenefitDurations)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectField
            name="inflationBenefitOption"
            disabled={isNotAvailableForNY}
            label={formatMessage(messages.inflationBenefitOption)}
            options={InflationBenefitOptions}
          />
        </Grid>
        {!careMatterII && (
          <Grid item xs={12} sm={6} md={4}>
            <SelectField
              name="returnOfPremium"
              textHint={<FormattedMessage {...messages.benefitDuration} />}
              hintPosition={width < 600 ? 'left' : 'top'}
              mobileTooltip={width < 600}
              label={formatMessage(messages.returnOfPremium)}
              disabled
              options={[{ value: 'alwaysIncluded', name: 'Always Included' }]}
            />
          </Grid>
        )}
        {careMatterII && (
          <Grid item xs={12} sm={6} md={4}>
            <CurrencyField
              name="lumpSumPremium"
              label={formatMessage(messages.lumpSumPremium)}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <StyledList className={values.exchange ? 'open-border' : ''}>
            <ListItemCheckboxFormik
              name="exchange"
              label={formatMessage(messages.exchange)}
            />
          </StyledList>
          {values.exchange && (
            <StyledBackgroundGrid container>
              {!careMatterII && (
                <Grid item xs={12} lg={12}>
                  <Typography>
                    {formatMessage(messages.MessageLabel1035)}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <CurrencyField
                  name="exchangeAmount"
                  label={formatMessage(messages.exchangeAmount)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="existingContract"
                  value="existingContract"
                  label={formatMessage(messages.existingContract)}
                  component={CheckBoxComponent}
                />
              </Grid>
            </StyledBackgroundGrid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

ForceAmountAndPremium.propTypes = {
  intl: intlShape,
};

export default injectIntl(ForceAmountAndPremium, messages);
