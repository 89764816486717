import { getCurrencyFormat } from '../../../utils/utils';

/**
 * Validate Force Amount Form
 * @param {object} values
 * @return {object} Return an object with the list of errors on Insurance form
 */

export const ForceAmountValidations = (values, productSettings) => {
  const errors = {};

  if (!values.premiumAmount && values.premiumAmount !== 0) {
    errors.premiumAmount = true;
  } else {
    const benefits = productSettings.benefitTypes
      .filter(item => item.benefitType.value === values.premiumOrBenefitType)
      .map(i => i.benefitsDuration)[0];

    const maxAmount = benefits
      .filter(item => item.benefitDuration.value === values.benefitDuration)
      .map(i => i.MaxAmount)[0];

    const minAmount = benefits
      .filter(item => item.benefitDuration.value === values.benefitDuration)
      .map(i => i.MinAmount)[0];

    if (values.premiumAmount > maxAmount) {
      errors.premiumAmount = `Maximum amount is ${getCurrencyFormat(
        maxAmount,
      )}`;
    }
    if (values.premiumAmount < minAmount) {
      errors.premiumAmount = `Minimum amount is ${getCurrencyFormat(
        minAmount,
      )}`;
    }
  }

  if (!values.scheduledPremium) {
    errors.scheduledPremium = true;
  }

  if (productSettings.isCareMatterII) {
    if (!values.refundOfPremium) {
      errors.refundOfPremium = true;
    }
  }

  if (!values.inflationBenefitOption) {
    errors.inflationBenefitOption = true;
  }

  if (values.exchange) {
    if (!values.exchangeAmount) {
      errors.exchangeAmount = true;
    }
    else {
      if (values.exchangeAmount > 1750000) {
         errors.exchangeAmount = 'Maximum amount is $1,750,000';
      }
    }
  }

  return errors;
};
