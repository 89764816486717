/* eslint-disable no-console */
import React, { useState, useReducer, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import {
  WizardContainer,
  StyledHeader,
  StyledStepsNavigation,
  StyledSteps,
  StyledStep,
  StyledNavigationControls,
  StyledStepsNavigationStep,
  StyledSummary,
  StyledSummaryStep,
} from './styles';
import {
  NEXT_STEP,
  BACK_STEP,
  GO_STEP,
  UPDATE_INITIAL_VALUES,
  MAX_AGE,
  MAX_AGE_YL
} from './constants';
import wizardReducer from './reducer';
import { getSettings } from '../../utils/api';
import WizardContext, { useWizardContext } from './WizardContext';

const defaultInitialState = {
  currentStep: 1,
  totalSteps: 3,
  isContinuable: true,
  isBackable: false,
  isLastPage: false,
  initialValues: {},
  isEditForm: false,
};

const filterSettings = (state, Settings) => {
  const isCareMatterII = Settings.statesList
    .filter(item => state === item.value)
    .map(i => i.isCareMattersII)[0];

  if (state === 'NY') {
    return Settings.productSettingsList[2];
  }
  if (state === 'SD') {
    return Settings.productSettingsList[3];
  }
  if (isCareMatterII === false) {
    const  productSettingsList = Settings.productSettingsList[1];
    if (state === 'CA') {
      productSettingsList.MaxAge = MAX_AGE_YL;
    } else {
      productSettingsList.MaxAge = MAX_AGE
    }
    return productSettingsList;
  }
  return Settings.productSettingsList[0];
};

let lastVisitedStep = 1;
const FormWizard = ({
  children,
  initialValues,
  validations,
  handleSubmit,
  resetOnSubmit,
  // ...rest
}) => {
  // Local States
  let localFormikProps = null;

  const [settings, setSettings] = useState();
  const [statesList, setStatesList] = useState();
  const [countiesList, setCountiesList] = useState();
  const [productSettings, setProductSettings] = useState();
  const [state, dispatch] = useReducer(wizardReducer, {
    ...defaultInitialState,
    initialValues,
  });
  const [fieldsChanged, setFieldsChanged] = useState([]);

  // TODO: Uncomment this when backend to get Product Settings is ready
  useEffect(() => {
    getSettings().then(result => {
      // meter settings al state
      setSettings(result.data);
      setStatesList(result.data.statesList);
      setCountiesList(result.data.counties);
      // filter settings
      setProductSettings(filterSettings(initialValues.issueState, result.data));
    });
  }, [initialValues]);

  // Scroll window to top when currentStep is updated
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.currentStep]);
  useEffect(() => {
    dispatch({
      type: UPDATE_INITIAL_VALUES,
      initialValues,
    });
    // is edit form
    if (initialValues.illustrationId) {
      lastVisitedStep = state.totalSteps;
    }
  }, [initialValues, state.totalSteps]);

  const handleNext = () => {
    // validate on next
    localFormikProps.validateForm().then(errors => {
      if (Object.keys(errors).length === 0 && errors.constructor === Object) {
        localFormikProps.submitForm();
      } else {
        localFormikProps.setTouched(errors);
        Object.keys(document.forms[0]).some(key => {
          const obj = document.forms[0][key];
          if (_.has(errors, obj.name)) {
            obj.focus();
            return true;
          }
          return false;
        });
      }
    });
  };

  const handlePrev = () => {
    localFormikProps.setTouched({});
    dispatch({ type: BACK_STEP });
  };
  const handleGoToStep = step => {
    localFormikProps.setTouched({});
    dispatch({ type: GO_STEP, step });
  };
  const handleValidations = values => {
    if (!validations) {
      return {};
    }
    return validations[state.currentStep - 1](values, productSettings);
  };
  const handleOnSubmit = (values, actions) => {
    if (state.isLastPage) {
      if (handleSubmit) {
        actions.setSubmitting(false);
        handleSubmit(values, actions);
      }
      if (resetOnSubmit) {
        resetWizardForm();
      }
    } else {
      localFormikProps.setTouched({});
      localFormikProps.setSubmitting(false);
      lastVisitedStep = Math.max(state.currentStep + 1, lastVisitedStep);
      dispatch({ type: NEXT_STEP, values });
    }
  };
  const resetWizardForm = () => {
    dispatch({ type: GO_STEP, step: 1 });
    localFormikProps.resetForm();
  };

  const changeOnForm = (fieldName, fieldValue) => {
    if (!_.find(fieldsChanged, n => n === fieldName)) {
      setFieldsChanged([...fieldsChanged, fieldName]);
    }

    setTimeout(() => {
      setFieldsChanged([]);
    }, 4000);

    localFormikProps.setFieldValue(fieldName, fieldValue);
  };

  const getProductSettings = issueState => {
    const filteredSettings = filterSettings(issueState, settings);
    setProductSettings(filteredSettings);
    localFormikProps.setFieldValue('product', filteredSettings.product);
    if (filteredSettings.IsCareMattersII) {
      localFormikProps.setFieldValue('quickPreview', true);
    }
  };

  const updateInflationBenefitOptionForNY = (benefitDuration, issueState) => {
    if (
      issueState === 'NY' &&
      (benefitDuration === '2' || benefitDuration === '3')
    ) {
      localFormikProps.setFieldValue('inflationBenefitOption', 'None');
    }
  };

  const wizardProps = {
    handleNext,
    handlePrev,
    handleSubmit,
    handleGoToStep,
    productSettings,
    statesList,
    countiesList,
    getProductSettings,
    updateInflationBenefitOptionForNY,
    changeOnForm,
    fieldsChanged,
    ...state,
  };

  return (
    <Formik
      initialValues={state.initialValues}
      validate={handleValidations}
      enableReinitialize
      validateOnBlur
      onSubmit={handleOnSubmit}
      render={formikProps => {
        localFormikProps = formikProps;
        const context = {
          ...wizardProps,
          ...formikProps,
          isModified: !_.isEmpty(formikProps.touched) && formikProps.dirty,
          lastVisitedStep,
        };
        if (!_.isEmpty(formikProps.touched) && formikProps.dirty) {
          lastVisitedStep = state.currentStep;
          context.lastVisitedStep = state.currentStep;
        }
        return (
          <WizardContext.Provider value={context}>
            <WizardContainer>{children(context)}</WizardContainer>
          </WizardContext.Provider>
        );
      }}
    />
  );
};

FormWizard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  validations: PropTypes.array,
  resetOnSubmit: PropTypes.bool,
};

const Header = ({ children }) =>
  children ? <StyledHeader>{children}</StyledHeader> : null;

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const StepsNavigation = ({ children }) => (
  <StyledStepsNavigation>{children}</StyledStepsNavigation>
);

StepsNavigation.propTypes = {
  children: PropTypes.array,
};

const StepsNavigationStep = ({ step, children }) => {
  // eslint-disable-next-line no-shadow
  const { currentStep, handleGoToStep, lastVisitedStep } = useWizardContext();
  const classIsActive = step === currentStep ? 'is-active' : '';
  const isVisited = step <= lastVisitedStep ? 'is-visited' : '';
  const handleClick = () => {
    if (isVisited !== '') {
      handleGoToStep(step);
    }
    return false;
  };
  return children ? (
    <StyledStepsNavigationStep
      className={[classIsActive, isVisited]}
      onClick={handleClick}
    >
      {children}
    </StyledStepsNavigationStep>
  ) : null;
};
StepsNavigationStep.propTypes = {
  step: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const Steps = ({ children }) => <StyledSteps>{children}</StyledSteps>;
Steps.propTypes = {
  children: PropTypes.array,
};

const Step = ({ step, children }) => {
  const { currentStep } = useWizardContext();

  return currentStep === step ? (
    <StyledStep>
      <Form>{children}</Form>
    </StyledStep>
  ) : null;
};
Step.propTypes = {
  step: PropTypes.number,
  children: PropTypes.any,
};

const PreviousButton = ({ children }) => {
  const { isBackable } = useWizardContext();

  return isBackable ? children : null;
};

const NextButton = ({ children }) => {
  const { isContinuable } = useWizardContext();

  return isContinuable ? children : null;
};

const SubmitButton = ({ children }) => {
  const { isLastPage } = useWizardContext();

  return isLastPage ? children : null;
};

const NavigationControls = ({ children }) => (
  <StyledNavigationControls>{children}</StyledNavigationControls>
);
NavigationControls.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};
const Summary = ({ children }) => <StyledSummary>{children}</StyledSummary>;
Summary.propTypes = {
  children: PropTypes.object,
};

const SummaryStep = ({ step, children }) => {
  const { currentStep, handleGoToStep } = useWizardContext();

  return (
    <StyledSummaryStep
      className={step === currentStep ? 'is-active' : ''}
      onClick={() => handleGoToStep(step)}
    >
      {children}
    </StyledSummaryStep>
  );
};
SummaryStep.propTypes = {
  step: PropTypes.object,
  children: PropTypes.object,
};

FormWizard.Summary = Summary;
FormWizard.SummaryStep = SummaryStep;
FormWizard.Navigation = StepsNavigation;
FormWizard.NavigationStep = StepsNavigationStep;
FormWizard.Steps = Steps;
FormWizard.Step = Step;
FormWizard.Header = Header;
FormWizard.NavigationControls = NavigationControls;
FormWizard.Next = NextButton;
FormWizard.Previous = PreviousButton;
FormWizard.Submit = SubmitButton;

export default FormWizard;
