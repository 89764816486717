import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const styles = () => ({
  root: {},
  closeStyle: {
    top: 10,
    right: 14,
    position: 'absolute',
    height: 31,
    width: 31,
    padding: '0px 0px 3px',
    fontSize: 20,
  },
  messageStyle: {
    padding: '4px 0px',
    lineHeight: '16px',
    whiteSpace: 'pre-wrap',
  },
  errorType: {
    backgroundColor: '#ff5252',
  },
});

const NotificationMessages = ({
  classes,
  isOpen,
  messages,
  onHandleClose,
  notificationType,
}) =>
  messages ? (
    <React.Fragment>
      <Snackbar
        ContentProps={{
          classes: {
            root:
              notificationType === 'Error' ? classes.errorType : classes.root,
          },
        }}
        open={isOpen}
        onClose={onHandleClose}
        TransitionComponent={TransitionUp}
        message={messages.map(message => (
          <div className={classes.messageStyle} key={message.MessageId}>
            {message.userMessage}
          </div>
        ))}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onHandleClose}
            className={classes.closeStyle}
          >
            x
          </IconButton>,
        ]}
      />
    </React.Fragment>
  ) : null;

NotificationMessages.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  messages: PropTypes.array,
  onHandleClose: PropTypes.func,
  notificationType: PropTypes.string,
};

export default withStyles(styles)(NotificationMessages);
