import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';

import enLocaleData from 'react-intl/locale-data/en';

import theme from './theme/theme';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
// import esLocaleData from 'react-intl/locale-data/es';
// import translations from './i18n/locales';

addLocaleData(enLocaleData);
// addLocaleData(esLocaleData);

const Render = () => (
  // Use locale on location
  // const locale = window.location.search.replace('?locale=', '') || 'en';
  // const messages = translations[locale];
  <IntlProvider locale="en">
    <Router>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </Router>
  </IntlProvider>
);
ReactDOM.render(<Render />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
