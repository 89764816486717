/**
 *
 * Button.js
 *
 */

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import variables from '../../theme/themeVars';

const MainButton = styled(Button)`
  border-radius: 4px;
  cursor: pointer;
  font-family: ${variables.fontRegular};
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0;
  text-align: center;
  transition: all 0.5s ease;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  padding: 6px 20px;
  text-decoration: none;

  span {
    span {
      display: inline-block;
    }
  }

  &:focus,
  &:focus:active {
    outline: none;
  }

  &[disabled] {
    opacity: 0.5;
  }

  @media only screen and (max-width: 768px) {
    min-width: 120px;
    outline: none;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
  @media only screen and (max-width: 360px) {
    margin-bottom: 5px;
  }
`;

export const PrimaryButton = styled(MainButton)`
  background-color: ${variables.nextFormBtnColor} !important;
  border: 1px solid ${variables.nextFormBtnColor} !important;
  color: #ffffff;

  &:hover {
    background-color: ${variables.nextFormBtnHover} !important;
  }
`;

export const InvertedButton = styled(MainButton)`
  background-color: #fff;
  border: 1px solid ${variables.backFormBtnText} !important;
  color: ${variables.backFormBtnText} !important;

  &:hover {
    background-color: ${variables.backFormBtnHover} !important;
    border: 1px solid ${variables.backFormBtnHover} !important;
    color: #ffffff !important;
  }
`;

export const TransparentButton = styled(MainButton)`
  border: 1px solid #ffffff;
  color: white;
  padding: 8px 20px;
  min-width: 120px;
  font-size: 15px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export default MainButton;
