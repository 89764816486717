import React, { useState, useEffect, createRef } from 'react';
import { getIllustrations, getIllustrationsCSV } from '../../utils/api';
import { createCSVDownload } from '../../utils/utils';
import { useAppContext } from '../App/AppContext';
import IllustrationHistoryComponent from './IllustrationHistoryComponent';

const initialValues = {
  data: [['']],
  page: 0,
  count: 0,
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100, 500],
  native: true,
};

const requestPayload = {
  currentPage: initialValues.page,
  pageSize: initialValues.rowsPerPage,
  sortBy: 'createdAt',
  sortDirection: 'descending',
  query: '',
};
const csvFileName = 'NW_CareMatters_Illustrations.csv';
const tablePaginationReactRef = createRef();
const muiDataTableRef = createRef();

const IllustrationHistoryContainer = () => {
  // TODO: This hook should be in a separate file.
  const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay], // Only re-call effect if value or delay changes
    );

    return debouncedValue;
  };

  const { configuration } = useAppContext();
  const [data, setData] = useState(initialValues.data);
  const [page, setPage] = useState(initialValues.page);
  const [count, setCount] = useState(initialValues.count);
  const [rowsPerPage, setRowsPerPage] = useState(initialValues.rowsPerPage);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setLoading] = useState(true);
  const debouncedSearchText = useDebounce(searchText, 500);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [requestTimeout, setRequestTimeout] = useState(null);
  const [serverDateTime, setServerDateTime] = useState();
  const minDate = new Date('01/01/2019');
  const [anchorFilter, setAnchorFilter] = useState(null);

  /**
   * Hook trigger during component inizialization
   */
  useEffect(() => {
    if (configuration.serverDateTime !== 'undefined') {
      setServerDateTime(configuration.serverDateTime);
    }
  }, [configuration]);

  // Get data on mount
  useEffect(() => {
    getData(requestPayload);
  }, []);

  // Get data when search
  useEffect(() => {
    if (
      (debouncedSearchText && debouncedSearchText.length > 1) ||
      debouncedSearchText === ''
    ) {
      requestPayload.query = debouncedSearchText;
      requestPayload.currentPage = initialValues.page;
      getData(requestPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  const onHandleOnDownload = () => {
    getIllustrationsCSV({
      ...requestPayload,
      currentPage: 1,
      pageSize: muiDataTableRef.current.props.options.count,
    }).then(result => {
      createCSVDownload(result.data, csvFileName);
    });
  };

  const onColumnSortChange = (changedColumn, direction) => {
    requestPayload.sortBy = changedColumn;
    requestPayload.sortDirection = direction;
    tablePaginationReactRef.current.props.changePage(initialValues.page);
  };

  const onChangePage = currentPage => {
    requestPayload.currentPage = currentPage;
    getData(requestPayload);
    window.scrollTo(0, 0);
  };

  const onChangeRowsPerPage = (numberOfRows = initialValues.rowsPerPage) => {
    setRowsPerPage(parseInt(numberOfRows, 10));
    requestPayload.pageSize = numberOfRows;
    requestPayload.currentPage = initialValues.page;
    getData(requestPayload);
    window.scrollTo(0, 0);
  };

  const onTableChange = (action, tableState) => {
    if (action === 'search') {
      setSearchText(tableState.searchText || '');
    }
    if (action === 'onSearchOpen') {
      setIsSearchOpen(true);
    }
  };

  const handleChange = ({ startDate, endDate }) => {
    clearTimeout(requestTimeout);
    const newStartDate = getRequestDate(minDate, startDate);
    const newEndDate = getRequestDate(serverDateTime, endDate);

    if (newStartDate.getTime() > newEndDate.getTime()) {
      return;
    }

    const requestTimeoutLocal = setTimeout(() => {
      if (newStartDate === null || newEndDate === null) return;

      const startDateString = `${newStartDate.getMonth() +
        1}/${newStartDate.getDate()}/${newStartDate.getFullYear()}`;

      const endDateString = `${newEndDate.getMonth() +
        1}/${newEndDate.getDate()}/${newEndDate.getFullYear()}`;

      sendDate(startDateString, endDateString);
    }, 500);
    setRequestTimeout(requestTimeoutLocal);
  };

  /**
   * Return a From Date selected by user or the minimun valid date that App should send
   * @returns {Date}
   */
  const getRequestDate = (defaultDate, date) =>
    date instanceof Date === false ? defaultDate : date;

  const handleChangeStart = startDate => {
    if (startDate === selectedStartDate) return;
    setSelectedStartDate(startDate);
    handleChange({ startDate, endDate: selectedEndDate || null });
  };

  const handleChangeEnd = endDate => {
    if (endDate === selectedEndDate) return;
    setSelectedEndDate(endDate);
    handleChange({ startDate: selectedStartDate || null, endDate });
  };

  const sendDate = (from, to) => {
    requestPayload.fromDate = from;
    requestPayload.toDate = to;
    tablePaginationReactRef.current.props.changePage(initialValues.page);
  };

  const getData = payload => {
    setLoading(true);
    setData(initialValues.data);
    getIllustrations({ ...payload, currentPage: payload.currentPage + 1 }).then(
      result => {
        setPage(payload.currentPage);
        setCount(result.data.recordCount);
        setData(result.data.results);
        setLoading(false);
      },
    );
  };

  const clearDate = () => {
    if (selectedStartDate || selectedEndDate) {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      handleChange({ startDate: null, endDate: null });
    }
    setAnchorFilter(null);
  };

  const clearSelectedStartDate = () => {
    setSelectedStartDate(null);
    handleChange({ startDate: null, endDate: selectedEndDate });
  };

  const clearSelectedEndDate = () => {
    setSelectedEndDate(null);
    handleChange({ startDate: selectedStartDate, endDate: null });
  };

  const handleOnFilter = (event = null) => {
    const {
      anchorFilter: anchorFilterState,
    } = muiDataTableRef.current.props.options;
    setAnchorFilter(anchorFilterState ? null : event.currentTarget);
  };

  const onSearchClose = () => {
    setIsSearchOpen(false);
  };

  const closeDateFilter = event => {
    if (!anchorFilter || anchorFilter.contains(event.target)) {
      return;
    }
    setAnchorFilter(null);
  };

  const openFilter = Boolean(anchorFilter);
  const idFilter = openFilter ? 'simple-popper' : undefined;
  const searchIconClass = isSearchOpen ? 'iconSearchActive' : 'iconSearchClose';
  return (
    <IllustrationHistoryComponent
      data={data}
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={initialValues.rowsPerPageOptions}
      searchText={searchText}
      isLoading={isLoading}
      anchorFilter={anchorFilter}
      idFilter={idFilter}
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      serverDateTime={serverDateTime}
      minDate={minDate}
      tableMethods={{
        onColumnSortChange,
        onChangePage,
        onChangeRowsPerPage,
        onTableChange,
        onSearchClose,
      }}
      clearDate={clearDate}
      handleChangeStart={handleChangeStart}
      handleChangeEnd={handleChangeEnd}
      handleOnDownload={onHandleOnDownload}
      handleOnFilter={handleOnFilter}
      closeDateFilter={closeDateFilter}
      openFilter={openFilter}
      tablePaginationReactRef={tablePaginationReactRef}
      muiDataTableRef={muiDataTableRef}
      clearSelectedStartDate={clearSelectedStartDate}
      clearSelectedEndDate={clearSelectedEndDate}
      searchIconClass={searchIconClass}
    />
  );
};

export default IllustrationHistoryContainer;
