import catalog from '../catalog';

export const GetBenefitsType = productSettings => {
  const benefitTypes = productSettings.benefitTypes.map(
    item => item.benefitType,
  );
  return benefitTypes;
};

export const GetScheduledPremiumOptions = (
  productSettings,
  age,
  values,
  changeOnForm,
) => {
  const inflation = values.inflationBenefitOption;
  const refund = values.refundOfPremium;
  let scheduledPremiumOptions;
  if (!productSettings.IsCareMattersII) {
    if (values.issueState !== 'CA') {
      scheduledPremiumOptions = productSettings.scheduledsPremium.filter(
        item => age <= item.maxAge,
      );
    } else {
      scheduledPremiumOptions = productSettings.scheduledsPremium;
    }
    if (values.issueState === 'NY') {
      scheduledPremiumOptions = filterScheduledPremiumOptionsForNY(
        values,
        age,
        scheduledPremiumOptions,
        changeOnForm,
      );
    }
  } else {
    const inflationBenefitsOption = productSettings.inflationBenefitsOption
      .filter(item => item.inflationBenefitOption.value === inflation)
      .map(i => i.refundsOfPremium)[0];

    let refundsOfPremium = inflationBenefitsOption
      .filter(item => refund === item.refundOfPremium.value)
      .map(i => i.scheduledsPremium)[0];

    if (!refundsOfPremium) {
      // set first value if the refund Of premium is not available.
      setTimeout(() => {
        changeOnForm(
          'refundOfPremium',
          inflationBenefitsOption[0].refundOfPremium.value,
        );
      });
      refundsOfPremium = inflationBenefitsOption[0].scheduledsPremium;
    }

      scheduledPremiumOptions = refundsOfPremium.filter(
        item => age <= item.maxAge,
      );
  }

  // set first value if the inflation benefits option is not available.
  const selectedScheduledPremium = scheduledPremiumOptions.filter(
    item => item.value === values.scheduledPremium,
  )[0];

  setTimeout(() => {
    if (!selectedScheduledPremium) {
      changeOnForm('scheduledPremium', scheduledPremiumOptions[0].value);
    }
  });

  return scheduledPremiumOptions;
};

export const GetBenefitDurations = (
  productSettings,
  type,
  values,
  age,
  changeOnForm,
) => {
  const benefits = productSettings.benefitTypes
    .filter(item => item.benefitType.value === type)
    .map(i => i.benefitsDuration)[0];
  let benefitsDuration = benefits.map(i => i.benefitDuration);

  if (values.issueState === 'NY') {
    benefitsDuration = filterBenefitsOptionsForNY(
      values,
      age,
      benefitsDuration,
      changeOnForm,
    );
  }

  return benefitsDuration;
};

export const GetRefundOfPremiumOptions = (
  productSettings,
  age,
  inflation,
  refund,
  changeOnForm) => {
  let refundsOfPremium = [];
  if (productSettings.IsCareMattersII) {
    const inflationBenefitsOption = productSettings.inflationBenefitsOption
      .filter(item => item.inflationBenefitOption.value === inflation)
      .map(i => i.refundsOfPremium)[0];

    refundsOfPremium = inflationBenefitsOption
      .filter(item => age <= item.refundOfPremium.maxAge)
      .map(i => i.refundOfPremium);
  }

  const selectedRefund = refundsOfPremium.filter(item => item.value === refund)[0];
  if (!selectedRefund && productSettings.IsCareMattersII) {
    // set first value if the refund Of premium is not available.
    setTimeout(() => {
      changeOnForm(
        'refundOfPremium',
        refundsOfPremium[0].value,
      );
    });
  }

  return refundsOfPremium;
};

export const GetInflationBenefitOptions = (
  productSettings,
  age,
  values,
  changeOnForm,
) => {
  let inflationBenefitsOption;

  if (!productSettings.IsCareMattersII) {
    if (values.issueState !== 'CA') {
      inflationBenefitsOption = productSettings.inflationBenefitOption.filter(
        item => age <= item.maxAge,
      );
    } else {
      inflationBenefitsOption = productSettings.inflationBenefitOption;
    }

    if (
      values.issueState === 'NY' &&
      (values.benefitDuration === '2' || values.benefitDuration === '3')
    ) {
      inflationBenefitsOption = [inflationBenefitsOption[0]];
    }
  } else {
    inflationBenefitsOption = productSettings.inflationBenefitsOption
      .filter(item => age <= item.inflationBenefitOption.maxAge)
      .map(i => i.inflationBenefitOption);
  }

  // set first value if the inflation benefits option is not available.
  const selectedInflationBenefit = inflationBenefitsOption.filter(
    item => item.value === values.inflationBenefitOption,
  )[0];
  setTimeout(() => {
    if (!selectedInflationBenefit) {
      changeOnForm('inflationBenefitOption', inflationBenefitsOption[0].value);
    }
  });

  return inflationBenefitsOption;
};

export const GetPremiumModeOptions = (
  scheduledPremium,
  premiumMode,
  changeOnForm,
) => {
  let premiumModeOptions = catalog.premiumModes;
  if (scheduledPremium === 'Single Premium') {
    premiumModeOptions = catalog.premiumModes.filter(
      item => item.value === 'Annual',
    );
    setTimeout(() => {
      if (premiumMode === 'Monthly') {
        changeOnForm('premiumMode', premiumModeOptions[0].value);
      }
    });
  }
  return premiumModeOptions;
};

const filterBenefitsOptionsForNY = (
  values,
  age,
  benefitsDuration,
  changeOnForm,
) => {
  let benefitsDurationNY = benefitsDuration;

   // set first value if the inflation benefits option is not available.
  const selectedBenefitsDurationNY = benefitsDurationNY.filter(
    item => item.value === values.benefitDuration,
  )[0];
  setTimeout(() => {
    if (!selectedBenefitsDurationNY) {
      changeOnForm('benefitDuration', benefitsDurationNY[0].value);
    }
  });

  return benefitsDurationNY;
};

const filterScheduledPremiumOptionsForNY = (
  values,
  age,
  scheduledPremiumOptions,
  changeOnForm,
) => {
  let scheduledPremiumOptionsNY = scheduledPremiumOptions;
  // set first value if the scheduled premium option is not available.
  const selectedscheduledPremiumOptionsNY = scheduledPremiumOptionsNY.filter(
    item => item.value === values.scheduledPremium,
  )[0];
  setTimeout(() => {
    if (!selectedscheduledPremiumOptionsNY) {
      changeOnForm('scheduledPremium', scheduledPremiumOptionsNY[0].value);
    }
  });

  return scheduledPremiumOptionsNY;
};
