import React, { useCallback, useLayoutEffect, createRef } from 'react';
import styled from 'styled-components';
import { Field, ErrorMessage } from 'formik';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import _ from 'lodash';
import RootRef from '@material-ui/core/RootRef';
import Hint from '../Hint';
import { useWizardContext } from '../FormWizard/WizardContext';

export const SelectStyle = styled(Select)`
  &.option-changed {
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
  }
`;

export const StyledIcons = styled.div`
  top: calc(50% - 12px);
  right: 0;
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  pointer-events: none;
`;
const SyledtHint = styled.div`
  top: calc(50% - 18px);
  position: absolute;
  right: 28px;
`;
export const BorderArrow = styled.div`
  display: inline;
  border-left: 1px solid #d9d9d9;
  padding-top: 10px;
  margin-left: 15px;
`;

const SelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
  label,
  showHelp,
  textHint,
  hintPosition,
  mobileTooltip,
  smallTooltip,
  handleOnChange,
  ...props
}) => {
  const domRef = createRef();
  const reactRef = createRef();
  useLayoutEffect(() => {
    const comp = reactRef.current;
    const elem = domRef.current;
    const ctrl = elem.getElementsByTagName('select')[0];
    if (comp.props.value == null) {
      ctrl.selectedIndex = -1;
    }
  });
  const { fieldsChanged } = useWizardContext();
  const handleSelectOnChange = useCallback(event => {
    if (handleOnChange) {
      handleOnChange(event);
    }
    setFieldValue(field.name, event.target.value);
  }, []);
  return (
    <div>
      <FormControl variant="filled" fullWidth disabled={props.disabled}>
        <InputLabel
          htmlFor="filled-age-simple"
          error={!!(touched[field.name] && errors[field.name])}
          shrink={!!field.value}
        >
          {label}
        </InputLabel>
        <RootRef rootRef={domRef}>
          <SelectStyle
            inputProps={{
              defaultChecked: false,
            }}
            autoWidth
            native
            IconComponent={() => (
              <StyledIcons>
                <BorderArrow>
                  <ArrowDropDown color="secondary" />
                </BorderArrow>
              </StyledIcons>
            )}
            input={<FilledInput name={field.name} disableUnderline fullWidth />}
            {...props}
            {...field}
            error={!!(touched[field.name] && errors[field.name])}
            onChange={handleSelectOnChange}
            ref={reactRef}
            className={
              _.find(fieldsChanged, n => n === field.name)
                ? `option-changed`
                : ''
            }
          >
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            ))}
          </SelectStyle>
        </RootRef>
        {showHelp && (
          <SyledtHint>
            {' '}
            <Hint
              role="tooltip"
              text={textHint}
              position={hintPosition}
              mobileTooltip={mobileTooltip}
              smallTooltip={smallTooltip}
            />
          </SyledtHint>
        )}
      </FormControl>
      <ErrorMessage component="div" name={field.name} />
    </div>
  );
};
const SelectField = props => <Field component={SelectComponent} {...props} />;

export default SelectField;
