import React from 'react';
import { Field } from 'formik';
import { useResize } from 'react-browser-hooks';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import NumberField from '../../../components/NumberField';
import DateField from '../../../components/DateField';
import InputComponent from '../../../components/InputComponent';
import SelectField from '../../../components/SelectField';
import { StyledList } from '../styles';
import messages from '../messages';
import catalog from '../catalog';
import { useWizardContext } from '../../../components/FormWizard/WizardContext';
import ListItemCheckboxFormik from '../../../components/ListItemCheckboxFormik';
import Hint from '../../../components/Hint';

const InsuredForm = ({ intl: { formatMessage } }) => {
  const {
    values,
    getProductSettings,
    statesList,
    countiesList,
  } = useWizardContext();
  const { width } = useResize();
  return (
    <React.Fragment>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <Field name="product" type="hidden" />
          <Field
            name="firstName"
            label={formatMessage(messages.firstName)}
            component={InputComponent}
            maxLength={32}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="lastName"
            label={formatMessage(messages.lastName)}
            component={InputComponent}
            maxLength={32}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SelectField
            name="sex"
            label={formatMessage(messages.sex)}
            options={catalog.genders}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SelectField
            name="ageValidation"
            label={formatMessage(messages.ageValidation)}
            options={catalog.ageValidations}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {values.ageValidation === 'birthday' ? (
            <DateField
              name="birthday"
              label={formatMessage(messages.birthday)}
            />
          ) : (
            <NumberField
              name="issueAge"
              label={formatMessage(messages.issueAge)}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={values.issueState === 'CA' ? 6 : 12}>
          {statesList && (
            <SelectField
              name="issueState"
              label={formatMessage(messages.issueState)}
              options={statesList}
              handleOnChange={event => {
                getProductSettings(event.target.value);
              }}
            />
          )}
        </Grid>
        {values.issueState === 'CA' && countiesList && (
          <Grid item xs={12} sm={6}>
            <SelectField
              name="county"
              label={formatMessage(messages.county)}
              options={countiesList}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <StyledList>
            <ListItemCheckboxFormik
              name="married"
              label={formatMessage(messages.married)}
            />
            <Divider component="li" />
            <ListItemCheckboxFormik
              name="tobacco"
              label={formatMessage(messages.tobacco)}
            />
            <Divider component="li" />
            <ListItemCheckboxFormik
              name="revised"
              label={formatMessage(messages.revised)}
            >
              <Hint
                text={<FormattedMessage {...messages.revisedHint} />}
                position="top"
                mobileTooltip={width < 600}
              />
            </ListItemCheckboxFormik>
          </StyledList>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

InsuredForm.propTypes = {
  intl: intlShape,
};

export default injectIntl(InsuredForm, messages);
