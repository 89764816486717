import axios from 'axios';
import AuthHelper from './auth';
import { getCleanApiURL, bindEvent, removeEvent, toQueryString } from './utils';

const auth = new AuthHelper();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache',
    Expires: 0,
  },
});

let isRefreshing = false;
let refreshSubscribers = [];

// Add a request interceptor to insert latest auth token
api.interceptors.request.use(
  config => {
    const token = auth.getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

// Middleware used to check 401 error and refresh token
api.interceptors.response.use(
  response => response,
  error => {
    const {
      config,
      response: { status },
    } = error;

    const originalRequest = config;

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        const apiURL = getCleanApiURL();
        const iframeId = 'refreshTokenIFrame';
        const messageListener = () => {
          isRefreshing = false;
          onRrefreshed();
          const iframeElement = document.getElementById(iframeId);
          if (document.body.contains(iframeElement)) {
            iframeElement.parentElement.removeChild(iframeElement);
          }
          removeEvent(window, 'message', messageListener);
        };
        // Create the iframe
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', apiURL);
        iframe.setAttribute('id', iframeId);
        iframe.style.display = 'none';
        bindEvent(window, 'message', messageListener);
        document.body.appendChild(iframe);
        // Validate if iframe is loaded
        setTimeout(() => {
          if (isRefreshing) {
            window.location.href = apiURL;
          }
        }, 15000);
      }

      const retryOrigReq = new Promise(resolve => {
        subscribeTokenRefresh(() => {
          const t = auth.getToken();
          originalRequest.headers.Authorization = `Bearer ${t}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    }
    return Promise.reject(error);
  },
);

const subscribeTokenRefresh = cb => {
  refreshSubscribers.push(cb);
};

const onRrefreshed = () => {
  refreshSubscribers.map(cb => cb());
  refreshSubscribers = [];
};

export function getApprovedStates() {
  return api.get('/states');
}

export const getConfiguration = () => api.get('/api/Configuration');

export const getSettings = () => api.get('/api/Products');

export const getIllustrations = payload => {
  const queryString = toQueryString(payload);
  return api.get(`/api/Illustrations?${queryString}`);
};

export const saveIllustration = values =>
  api.post('/api/Illustrations', values);

export const getIllustration = id => api.get(`/api/Illustrations/${id}`);

export const getIllustrationsCSV = payload => {
  const queryString = toQueryString(payload);
  return api.get(`/api/Illustrations/csv/?${queryString}`);
};
