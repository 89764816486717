import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import NumberFormat from 'react-number-format';
import {
  ErrorMessageMask,
  TextFieldStyle,
} from '../../containers/HomePage/styles';

const NumberFormatCustom = ({ inputRef, onChange, ...rest }) => (
  <NumberFormat
    {...rest}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange(values);
    }}
    format="##"
    pattern="\d*"
  />
);

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberTextfield = props => {
  const {
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    handleOnChange,
    ...rest
  } = props;

  const handleTextFieldChange = useCallback(values => {
    if (handleOnChange) {
      handleOnChange(values);
    }
    setFieldValue(field.name, values.floatValue);
  }, []);

  return (
    <React.Fragment>
      <TextFieldStyle
        type="text"
        margin="none"
        variant="filled"
        fullWidth
        error={!!(touched[field.name] && errors[field.name])}
        InputProps={{
          disableUnderline: true,
          inputComponent: NumberFormatCustom,
        }}
        autoComplete="off"
        {...rest}
        {...field}
        onChange={handleTextFieldChange}
      />
      <ErrorMessageMask component="div" name={field.name} />
    </React.Fragment>
  );
};

NumberTextfield.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  handleOnChange: PropTypes.func,
};

const NumberField = props => <Field component={NumberTextfield} {...props} />;

export default NumberField;
