/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { ErrorMessageMask } from '../../containers/HomePage/styles';

const InputComponent = ({
  field, // { name, value, onChange, onBlur }
  maxLength,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div>
    <TextField
      type="text"
      margin="none"
      variant="filled"
      fullWidth
      inputProps={{
        maxLength,
      }}
      InputProps={{
        disableUnderline: true,
      }}
      {...field}
      {...props}
      error={!!(touched[field.name] && errors[field.name])}
      autoComplete="off"
    />
    <ErrorMessageMask component="div" name={field.name} />
  </div>
);

InputComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  maxLength: PropTypes.number,
};

export default InputComponent;
