import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import NumberFormat from 'react-number-format';
import {
  ErrorMessageMask,
  TextFieldStyle,
} from '../../containers/HomePage/styles';
import {
  splitDecimal,
  limitToScale,
  applyThousandSeparator,
  getNumbersAndDecimalsOnly,
} from '../../utils/utils';

const CurrencyFormat = props => {
  const {
    inputRef,
    onChange,
    decimalscale,
    fixedDecimalScale,
    returntypevalue,
    thousandSeparator,
    prefix,
    suffix,
    decimalSeparator,
    ...rest
  } = props;
  // values : {formattedValue: "$34", value: "34", floatValue: 34}
  const handleOnChange = values => {
    let value = null;
    switch (returntypevalue) {
      case 'formattedValue':
        value =
          values.formattedValue === undefined ? '' : values.formattedValue;
        break;
      case 'floatValue':
        value = values.floatValue === undefined ? '' : values.floatValue;
        break;
      default:
        value = values.value === undefined ? '' : values.value;
        break;
    }
    return onChange({
      currentTarget: {
        value,
      },
      target: {
        value,
      },
    });
  };
  const handleFormat = formattedValue => {
    // if decimal scale is zero remove decimal and number after decimalSeparator
    if (decimalSeparator && decimalscale === 0) {
      formattedValue = formattedValue.split(decimalSeparator)[0];
    }
    let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(
      formattedValue,
      false,
    );

    const hasDecimalSeparator =
      formattedValue.indexOf('.') !== -1 ||
      (decimalscale && fixedDecimalScale && !!afterDecimal);

    if (decimalscale !== undefined && afterDecimal !== '')
      afterDecimal = limitToScale(
        afterDecimal,
        decimalscale,
        fixedDecimalScale,
      );
    if (thousandSeparator) {
      beforeDecimal = applyThousandSeparator(beforeDecimal, thousandSeparator);
    }
    if (prefix) beforeDecimal = prefix + beforeDecimal;
    if (suffix) afterDecimal += suffix; // restore negation sign
    if (addNegation) beforeDecimal = `-${beforeDecimal}`;
    return (
      beforeDecimal +
      ((hasDecimalSeparator && decimalSeparator) || '') +
      afterDecimal
    );
  };
  const handleRemoveFormatting = val => getNumbersAndDecimalsOnly(val);
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={values => {
        // values : {formattedValue: "$34", value: "34", floatValue: 34}
        handleOnChange(values);
      }}
      thousandSeparator
      inputMode="decimal"
      allowNegative={false}
      decimalScale={decimalscale}
      prefix="$"
      format={handleFormat}
      removeFormatting={handleRemoveFormatting}
      pattern="\d*"
    />
  );
};

CurrencyFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  decimalscale: PropTypes.number,
  returntypevalue: PropTypes.string,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  fixedDecimalScale: PropTypes.bool,
};

CurrencyFormat.defaultProps = {
  decimalscale: 2,
  fixedDecimalScale: true,
  returntypevalue: 'floatValue',
  thousandSeparator: ',',
  decimalSeparator: '.',
  prefix: '$',
};

const CurrencyTextfield = props => {
  const {
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    handleOnChange,
    ...rest
  } = props;

  const handleTextFieldChange = useCallback(event => {
    if (handleOnChange) {
      handleOnChange(event.currentTarget.value);
    }
    setFieldValue(field.name, event.currentTarget.value);
  }, []);

  return (
    <React.Fragment>
      <TextFieldStyle
        type="text"
        margin="none"
        variant="filled"
        fullWidth
        error={!!(touched[field.name] && errors[field.name])}
        InputProps={{
          disableUnderline: true,
          inputComponent: CurrencyFormat,
        }}
        autoComplete="off"
        {...rest}
        {...field}
        onChange={handleTextFieldChange}
      />
      <ErrorMessageMask component="div" name={field.name} />
    </React.Fragment>
  );
};

CurrencyTextfield.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  handleOnChange: PropTypes.func,
};

const CurrencyField = props => (
  <Field component={CurrencyTextfield} {...props} />
);

export default CurrencyField;
