import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import messages from './messages';
import {
  RESULT_MESSAGES_SUCCESS,
  RESULT_MESSAGES_ERROR,
  SERVER_ERROR_MESSAGE,
} from './constants';
import FormWizard from '../../components/FormWizard';
import { PrimaryButton, InvertedButton } from '../../components/Button';
import ButtonProgress from '../../components/Button/ButtonProgress';
import NotificationMessages from '../../components/NotificationMessages';
import InsuredForm from './forms/InsuredForm';
import ForceAmountAndPremium from './forms/ForceAmountAndPremium';
import OutputCustomization from './forms/OutputCustomization';
import {
  InsuredValidations,
  ForceAmountValidations,
  OutputValidations,
} from './validators';
import { saveIllustration, getIllustration } from '../../utils/api';
import {
  isSafari,
  getFirstName,
  getLastName,
  getMiddleName,
} from '../../utils/utils';
import { useAppContext } from '../App/AppContext';
import { PopupWarningText } from './styles';

const stepTitles = [
  'Insured',
  'Face Amount and Premium',
  'Output Customization',
];

const startValues = {
  // Insured
  product: 'Nationwide CareMatters II',
  firstName: '',
  lastName: '',
  sex: undefined,
  ageValidation: 'issueAge',
  issueAge: '',
  birthday: '',
  issueState: undefined,
  married: false,
  tobacco: false,
  revised: false,
  county: undefined,
  // Face amount and premium
  premiumOrBenefitType: 'Premium',
  advancedPremiumFund: false,
  premiumAmount: 100000,
  scheduledPremium: 'Single Premium',
  refundOfPremium: 'Maximum LTC Benefit',
  premiumMode: 'Annual',
  benefitDuration: '6',
  inflationBenefitOption: 'None',
  returnOfPremium: 'alwaysIncluded',
  lumpSumPremium: '',
  exchange: false,
  existingContract: false,
  exchangeAmount: '',
  // Output Customization
  generalLedger: true,
  premiumSummary: true,
  selectAll: false,
  annualCost: false,
  includeIrrColumn: false,
  quickPreview: true,
  occReportLabel: false,
  monthlyCost: false,
  yearsForMonthlyOutput: '',
  illustrationType: 'Guaranteed Interest, Guaranteed Charges',
  preparedBy: '',
};

const mapValues = values => ({
  product: values.product, // This is harcoded on initial values
  revisedIllustration: values.revised,
  insured: {
    issueState: values.issueState,
    county: values.issueState === 'CA' ? values.county : undefined,
    lastName: values.lastName,
    firstName: values.firstName,
    gender: values.sex,
    issueAgeOrDateOfBirth:
      values.ageValidation === 'issueAge' ? values.issueAge : values.birthday,
    tobaccoUser: values.tobacco,
    marriedOrCivilUnionOrDomesticPartnership: values.married,
  },
  proposal: {
    premiumOrBenefit: values.premiumOrBenefitType,
    premiumOrBenefitAmount: values.premiumAmount,
    scheduledPremium: values.scheduledPremium,
    refundOfPremium: values.refundOfPremium,
    premiumMode: values.premiumMode,
    monthToBeginScheduledPremium: 0, // TODO: Review this field
    benefitDuration: values.benefitDuration,
    InflationBenefit: values.inflationBenefitOption,
    inflationBenefitOption: values.inflationBenefitOption,
    lumpSumPremium: values.lumpSumPremium,
    '1035Exchange': values.exchange,
    exchangeAmount: values.exchange ? values.exchangeAmount : 0,
    existingContractMEC: values.existingContract,
    '1035ExchangeInfo': !values.exchange
      ? undefined
      : {
        exchangeAmount: values.exchange ? values.exchangeAmount : 0,
        existingContractMEC: values.existingContract,
      },
  },
  // TODO: Review this pending fields
  // advancedPremiumFund: false,
  // returnOfPremium: 'alwaysIncluded',

  illustrationIndicators: {
    generalLedger: values.generalLedger,
    premiumSummary: values.premiumSummary,
    displayIndividualRateOfReturnColumn: values.includeIrrColumn,
    inputSummary: true, // TODO: This is harcoded because is required on CareMatters II (Not in design)
    quickView: values.quickPreview,
    annualCostSummary: values.annualCost,
    occReport: values.occReportLabel,
    monthlyCostSummary: values.monthlyCost,
    whichIllustration: values.monthlyCost ? values.illustrationType : '', // 'Guaranteed Interest, Guaranteed Charges'
    yearsForMonthlyOutput: values.monthlyCost
      ? values.yearsForMonthlyOutput
      : '',
  },
  advisorDetails: {
    firstName: getFirstName(values.preparedBy),
    middleName: getMiddleName(values.preparedBy),
    lastName: getLastName(values.preparedBy),
  },
});

const mapValuesToState = values => {
  const isnum = /^\d+$/.test(values.issueAgeOrDateOfBirth);

  return {
    // Insured
    product: values.product,
    firstName: values.insuredFirstName,
    lastName: values.insuredLastName,
    sex: values.insuredGender,
    ageValidation: isnum ? 'issueAge' : 'birthday',
    issueAge: isnum ? parseInt(values.issueAgeOrDateOfBirth, 10) : '',
    birthday: isnum ? '' : values.issueAgeOrDateOfBirth,
    issueState: values.insuredIssueState,
    married: values.insuredMarriedOrCivilUnion,
    tobacco: values.insuredTobaccoUser,
    revised: values.revisedIllustration,
    county: values.insuredCounty || undefined,
    // Face amount and premium
    premiumOrBenefitType: values.proposalPremiumOrBenefit,
    advancedPremiumFund: false,
    premiumAmount: values.proposalPremiumOrBenefitAmount,
    scheduledPremium: values.proposalSchedulePremium,
    refundOfPremium: !values.proposalRefundOfPremium
      ? 'Maximum LTC Benefit'
      : values.proposalRefundOfPremium,
    premiumMode: values.proposalPremiumMode,
    benefitDuration: values.proposalBenefitDuration.toString(),
    inflationBenefitOption: values.proposalInflationBenefit,
    returnOfPremium: 'alwaysIncluded',
    lumpSumPremium:
      values.proposalLumpSumPremium > 0 ? values.proposalLumpSumPremium : '',
    exchange: values.proposal1035Exchange,
    existingContract: values.proposalExistingContractMEC,
    exchangeAmount: values.proposalExchangeAmount,
    // Output Customization
    selectAll:
      values.indicatorsGeneralLedger &&
      values.indicatorsPremiumSummary &&
      values.indicatorsAnnualCostSummary &&
      values.indicatorsDisplayIndividualRate &&
      values.indicatorsQuickView &&
      values.indicatorsOccReport &&
      values.indicatorsMonthlyCostSummary,
    generalLedger: values.indicatorsGeneralLedger,
    premiumSummary: values.indicatorsPremiumSummary,
    annualCost: values.indicatorsAnnualCostSummary,
    includeIrrColumn: values.indicatorsDisplayIndividualRate,
    quickPreview: values.indicatorsQuickView,
    occReportLabel: values.indicatorsOccReport,
    monthlyCost: values.indicatorsMonthlyCostSummary,
    yearsForMonthlyOutput: values.indicatorsYearsForMonthlyOutput,
    illustrationType: 'Guaranteed Interest, Guaranteed Charges',
    preparedBy: values.preparedBy,
  };
};

const HomePage = ({ intl: { formatMessage }, match, history }) => {
  // set Presented by
  const { configuration } = useAppContext();
  startValues.preparedBy = configuration.user.preparedBy;

  // Button status
  const [isLoading, setIsLoading] = useState(false);
  const [statusResultType, setStatusResultType] = useState('');
  // Server result messages
  const [serverResultMessages, setServerResultMessages] = useState();
  const [isNotificationDisplayed, setIsNotificationDisplayed] = useState(false);
  // Initial Values
  const [initialValues, setInitialValues] = useState(startValues);
  const [illustrationId, setIllustrationId] = useState(match.params.id);

  useEffect(() => {
    if (illustrationId) {
      getIllustration(illustrationId)
        .then(result => {
          setInitialValues({
            ...mapValuesToState(result.data),
            illustrationId,
          });
        })
        .catch(() => {
          history.push('/NotFound');
        });
    }
  }, [history, illustrationId]);

  const handleShowNotification = () => {
    setIsNotificationDisplayed(false);
  };
  const handleOnSubmit = values => {
    let windowReference;
    const valuesMapped = mapValues(values);
    if (isSafari) {
      windowReference = window.open();
    }
    setStatusResultType('');
    setIsLoading(true);
    setServerResultMessages();
    saveIllustration(valuesMapped)
      .then(response => {
        setIsLoading(false);
        setStatusResultType(RESULT_MESSAGES_SUCCESS);
        setServerResultMessages(response.data.messages);
        setIsNotificationDisplayed(true);
        const pdfURL = `${process.env.REACT_APP_API_URL}/api/Illustrations/${
          response.data.pdfGuid
        }/pdf`;

        if (isSafari) {
          windowReference.location = pdfURL;
        } else {
          window.open(pdfURL);
        }

        // redirect to the illustration
        history.push(`/Illustration/${response.data.internalIllustrationId}`);
        setIllustrationId(response.data.internalIllustrationId);
      })
      .catch(error => {
        if (isSafari) {
          windowReference.close();
        }
        try {
          const { data, status } = error.response;
          setIsLoading(false);
          setStatusResultType(RESULT_MESSAGES_ERROR);
          if (_.isObject(data) && status === 400) {
            setServerResultMessages([
              {
                MessageId: '1',
                userMessage: SERVER_ERROR_MESSAGE,
              },
            ]);
          } else {
            setServerResultMessages(data);
          }
          setIsNotificationDisplayed(true);
        } catch (err) {
          setServerResultMessages([
            {
              MessageId: '1',
              userMessage: SERVER_ERROR_MESSAGE,
            },
          ]);
          setIsNotificationDisplayed(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <FormWizard
      numberSteps={3}
      initialValues={initialValues}
      handleSubmit={handleOnSubmit}
      validations={[
        InsuredValidations,
        ForceAmountValidations,
        OutputValidations,
      ]}
      issueState={!initialValues.issueState ? 'AK' : initialValues.issueState}
    >
      {({ currentStep, handleNext, handlePrev }) => (
        <React.Fragment>
          <FormWizard.Navigation>
            <FormWizard.Header>
              <React.Fragment>
                {!illustrationId
                  ? formatMessage(messages.wizardTitle)
                  : formatMessage(messages.wizardTitleEdit)}
              </React.Fragment>
            </FormWizard.Header>
            {stepTitles.map((stepTitle, index) => (
              <FormWizard.NavigationStep key={stepTitle} step={index + 1}>
                {stepTitle}
              </FormWizard.NavigationStep>
            ))}
          </FormWizard.Navigation>
          <FormWizard.Steps>
            <FormWizard.Step step={1}>
              <Typography variant="h5" color="textSecondary">
                {formatMessage(messages.insuredInformation)}
              </Typography>
              <InsuredForm />
            </FormWizard.Step>
            <FormWizard.Step step={2}>
              <Typography variant="h5" color="textSecondary">
                {formatMessage(messages.faceAmountAndPremium)}
              </Typography>
              <ForceAmountAndPremium />
            </FormWizard.Step>
            <FormWizard.Step step={3}>
              <Typography variant="h5" color="textSecondary">
                {formatMessage(messages.outputCustomization)}
              </Typography>
              <OutputCustomization />
            </FormWizard.Step>
          </FormWizard.Steps>
          <NotificationMessages
            isOpen={isNotificationDisplayed}
            messages={serverResultMessages}
            onHandleClose={handleShowNotification}
            notificationType={statusResultType}
          />
          <FormWizard.NavigationControls>
            <React.Fragment>
              <FormWizard.Next>
                <PrimaryButton
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {formatMessage(messages.continueTo, {
                    step: stepTitles[currentStep],
                  })}
                </PrimaryButton>
              </FormWizard.Next>
              <FormWizard.Submit>
                <ButtonProgress onClick={handleNext} loading={isLoading}>
                  {formatMessage(messages.runAndExportIllustration)}
                </ButtonProgress>
              </FormWizard.Submit>
              <FormWizard.Previous>
                <InvertedButton
                  type="button"
                  variant="outlined"
                  onClick={handlePrev}
                >
                  {formatMessage(messages.back)}
                </InvertedButton>
              </FormWizard.Previous>
              {currentStep === 3 && (
                <PopupWarningText>
                  {formatMessage(messages.popupMessageWarning)}
                </PopupWarningText>
              )}
            </React.Fragment>
          </FormWizard.NavigationControls>
        </React.Fragment>
      )}
    </FormWizard>
  );
};

HomePage.propTypes = {
  intl: intlShape,
};

export default injectIntl(HomePage, messages);
