import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { Typography, Grid, Paper, Divider } from '@material-ui/core/';
import OutputPreview from '../../../components/OutputPreviewBox';
import GeneralPreview from '../../../images/output/preview-1.png';
import SummaryPreview from '../../../images/output/preview-2.png';
import AnnualPreview from '../../../images/output/preview-3.png';
import IrrPreview from '../../../images/output/preview-4.png';
import QuickPreview from '../../../images/output/preview-5.png';
import OccPreview from '../../../images/output/preview-6.png';
import MonthlyCostPreview from '../../../images/output/preview-7.png';
import CheckBoxComponent from '../../../components/CheckBoxComponent';
import SelectField from '../../../components/SelectField';
import { useWizardContext } from '../../../components/FormWizard/WizardContext';
import MonthYearField from '../../../components/MonthYearField';
import InputComponent from '../../../components/InputComponent';
import messages from '../messages';
import catalog from '../catalog';

export const Wrapper = styled.div`
  .selected-check {
    img {
      background-image: linear-gradient(
        180deg,
        rgba(52, 96, 148, 0.1) 0%,
        rgba(52, 96, 148, 0.25) 100%
      );
      background-size: cover;
    }
  }
`;

export const SelectAllWrapper = styled.div`
  &.select-some {
    background-color: red;
    input {
      background-color: red;
    }
  }
`;

export const MonthlyCost = styled.div`
  border: 1px solid transparent;
  margin-top: 0px;
  min-height: 183px;

  &.active-checkbox {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin: 0px 0px 0px -16px;
    padding: 0px 16px;

    input,
    select {
      padding-top: 1.4em;
    }

    @media only screen and (max-width: 1023px) {
      margin: 0px;
      padding: 0px;
      border: none;
    }

    @media only screen and (max-width: 600px) and (orientation: portrait) {
      label {
        font-size: 14px;
      }
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: inline-block;
`;

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: 70,
    color: '#333333',
    fontFamily: 'Helvetica Neue',
    fontSize: 15,
  },
  firstWrapper: {
    border: '1px solid #d9d9d9',
    backgroundColor: '#fafafa',
    marginBottom: 45,
    overflow: 'hidden',
  },
  secondWrapper: {
    border: '1px solid #d9d9d9',
    backgroundColor: '#fafafa',
    marginBottom: 10,
    overflow: 'hidden',
  },
  firstContainer: {
    padding: '12px 16px 20px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 16px 20px 32px',
    },
  },
  containerAll: {
    padding: '12px 16px 0px',
    backgroundColor: '#ffffff',
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      padding: '12px 16px 0px 32px',
    },
  },
  secondContainer: {
    padding: '12px 16px 24px',
    minHeight: 398,
    [theme.breakpoints.up('md')]: {
      padding: '12px 16px 24px 32px',
    },
  },
  requiredTitle: {
    color: '#333333',
    fontFamily: 'Gotham-Book',
    fontSize: 16,
  },
  selectAllTitle: {
    color: '#003b5c',
    fontFamily: 'Gotham-Book',
    fontSize: 16,
  },
  dropdown: {
    maxWidth: 330,
    marginLeft: 0,
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
      minWidth: 330,
      marginTop: 0,
      marginLeft: 16,
      verticalAlign: 'top',
    },
  },
  preparedBy: {
    marginBottom: 30,
  },
});

let selectedItems = 0;
const maxSelectedItems = 5;

const OutputCustomization = ({ classes, intl: { formatMessage } }) => {
  const { values, setValues, productSettings } = useWizardContext();
  const maxSelect = productSettings.IsCareMattersII ? 4 : maxSelectedItems;

  const countSelectItems = () => {
    selectedItems = 0;
    if (values.annualCost) selectedItems++;
    if (values.includeIrrColumn) selectedItems++;
    if (values.quickPreview && !productSettings.IsCareMattersII)
      selectedItems++;
    if (values.occReportLabel) selectedItems++;
    if (values.monthlyCost) selectedItems++;
  };
  countSelectItems();

  const selectAllFunc = () => {
    const isAllSelected = !values.selectAll;
    selectedItems = isAllSelected ? maxSelect : 0;

    setValues({
      ...values,
      annualCost: isAllSelected,
      includeIrrColumn: isAllSelected,
      quickPreview: productSettings.IsCareMattersII ? true : isAllSelected,
      occReportLabel: isAllSelected,
      monthlyCost: isAllSelected,
    });
  };

  const selectFunc = inputName => {
    const isSelected = !values[inputName];

    if (selectedItems >= 0 && selectedItems <= maxSelect) {
      selectedItems = isSelected ? selectedItems + 1 : selectedItems - 1;
    }

    if (selectedItems < maxSelect) {
      setValues({
        ...values,
        selectAll: false,
        [inputName]: isSelected,
      });
    }

    if (selectedItems === maxSelect) {
      setValues({
        ...values,
        selectAll: true,
        [inputName]: isSelected,
      });
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.preparedBy}>
        <Field
          name="preparedBy"
          label={formatMessage(messages.preparedBy)}
          component={InputComponent}
          maxLength={100}
        />
      </Grid>
      <Paper className={classes.firstWrapper} elevation={0}>
        <Grid container spacing={16} className={classes.firstContainer}>
          <Grid item xs={12} lg={12}>
            <Typography className={classes.requiredTitle}>
              {formatMessage(messages.requiredOutput)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <OutputPreview
              name="generalLedger"
              value="generalLedger"
              label={formatMessage(messages.generalLedger)}
              route={GeneralPreview}
              checked
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <OutputPreview
              name="premiumSummary"
              value="premiumSummary"
              label={formatMessage(messages.premiumSummary)}
              route={SummaryPreview}
              checked
              disabled
            />
          </Grid>
          {productSettings.IsCareMattersII && (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutputPreview
                name="quickPreview"
                value="quickPreview"
                label={formatMessage(messages.quickPreview)}
                route={QuickPreview}
                isSelected={values.quickPreview}
                checked
                disabled
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <Paper className={classes.secondWrapper} elevation={0}>
        <Grid container spacing={16} className={classes.containerAll}>
          <Grid item xs={12}>
            <Typography className={classes.selectAllTitle}>
              {formatMessage(messages.selectAdditionalOutput)}
            </Typography>
            <Field
              name="selectAll"
              value="selectAll"
              label="Select All"
              component={CheckBoxComponent}
              onClick={selectAllFunc}
              partialSelect={!!(selectedItems > 0 && selectedItems < maxSelect)}
            />
          </Grid>
        </Grid>
        <Divider />
        <Wrapper>
          <Grid container spacing={16} className={classes.secondContainer}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutputPreview
                name="annualCost"
                value="annualCost"
                label={formatMessage(messages.annualCost)}
                route={AnnualPreview}
                isSelected={values.annualCost}
                onClick={() => selectFunc('annualCost')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutputPreview
                name="includeIrrColumn"
                value="includeIrrColumn"
                label={formatMessage(messages.includeIrrColumn)}
                route={IrrPreview}
                isSelected={values.includeIrrColumn}
                onClick={() => selectFunc('includeIrrColumn')}
              />
            </Grid>
            {!productSettings.IsCareMattersII && (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <OutputPreview
                  name="quickPreview"
                  value="quickPreview"
                  label={formatMessage(messages.quickPreview)}
                  route={QuickPreview}
                  isSelected={values.quickPreview}
                  onClick={() => selectFunc('quickPreview')}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutputPreview
                name="occReportLabel"
                value="occReportLabel"
                label={formatMessage(messages.occReportLabel)}
                route={OccPreview}
                isSelected={values.occReportLabel}
                onClick={() => selectFunc('occReportLabel')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={!productSettings.IsCareMattersII ? 8 : 12} lg={8}>
              <MonthlyCost
                className={values.monthlyCost ? 'active-checkbox' : ''}
              >
                <OutputPreview
                  name="monthlyCost"
                  value="monthlyCost"
                  label={formatMessage(messages.monthlyCost)}
                  route={MonthlyCostPreview}
                  isSelected={values.monthlyCost}
                  onClick={() => selectFunc('monthlyCost')}
                />
                {values.monthlyCost && (
                  <React.Fragment>
                    <Grid item className={classes.dropdown}>
                      <SelectField
                        name="illustrationType"
                        label={formatMessage(messages.illustrationType)}
                        options={catalog.illustrationType}
                        disabled
                      />
                      <MonthYearField
                        name="yearsForMonthlyOutput"
                        label={formatMessage(messages.yearsForMonthlyOutput)}
                      />
                    </Grid>
                  </React.Fragment>
                )}
              </MonthlyCost>
            </Grid>
          </Grid>
        </Wrapper>
      </Paper>
    </React.Fragment>
  );
};

OutputCustomization.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape,
};

export default compose(withStyles(styles))(
  injectIntl(OutputCustomization, messages),
);
