import {
  NEXT_STEP,
  BACK_STEP,
  GO_STEP,
  UPDATE_INITIAL_VALUES,
} from './constants';

const wizardReducer = (state, action) => {
  switch (action.type) {
    case GO_STEP: {
      return {
        ...state,
        currentStep: action.step,
        isContinuable: !(action.step >= state.totalSteps),
        isBackable: action.step > 1,
        isLastPage: action.step === state.totalSteps,
      };
    }
    case NEXT_STEP: {
      const currentStep = Math.min(state.currentStep + 1, state.totalSteps);
      return {
        ...state,
        currentStep,
        isContinuable: !(currentStep >= state.totalSteps),
        isBackable: currentStep > 1,
        isLastPage: currentStep === state.totalSteps,
        initialValues: action.values,
      };
    }
    case BACK_STEP: {
      const currentStep = Math.max(state.currentStep - 1, 1);
      return {
        ...state,
        currentStep,
        isContinuable: !(currentStep >= state.totalSteps),
        isBackable: currentStep > 1,
        isLastPage: currentStep === state.totalSteps,
      };
    }
    case UPDATE_INITIAL_VALUES: {
      return {
        ...state,
        initialValues: action.initialValues,
        isEditForm:
          action.initialValues.illustrationId &&
          action.initialValues.illustrationId > 1,
      };
    }
    default: {
      return state;
    }
  }
};

export default wizardReducer;
